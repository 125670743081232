import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, TextareaAutosize } from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core//MenuItem";
import Select from "@material-ui/core/Select";
import { CircularProgress } from '@material-ui/core';

import SweetAlert from "react-bootstrap-sweetalert";

let schema = yup.object({
  categoryalias: yup.string().required("Category alias is required"),
  categoryname: yup.string().required("Category name is required").typeError(""),
  sortOrder: yup.string().typeError("Sort order is required"),
});

function CategoryAdd({ server, history, access_token, role }) {

  const [errortext, seterrortext] = useState("");
  const [alert, setAlert] = useState(null);
  const [onLoading, setOnLoading] = React.useState(false);

  useEffect(() => {
    if (role?.id !== 1) {
      if (role?.id === 2) {
        history.push("/Distributer");
      }
      if (role?.id === 3) {
        history.push("/Retailer");
      }
      if (role?.id === 4) {
        history.push("/Games");
      }
    }
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  const added = () => {
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        Category has been created
      </SweetAlert>
    );

    history.push('/Category')
  };

  const addNewCategory = (values, resetForm) => {

    setOnLoading(true);

    const data = {
      alias: values.categoryalias,
      name: values.categoryname,
      sort_order: values.sortOrder,
      presentation_type: values.presentation_type,
    };
    

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://backend.arihantauto.in/' + "api/category", data, config)
      .then((response) => {        
        added();
        resetForm();
        seterrortext("");
      })
      .catch((error) => {
        const errormessage = error.response.data.errors.alias[0];
        seterrortext(errormessage);
        setOnLoading(false);
      });
  };

  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <Formik
          initialValues={{
           categoryname: "",
           categoryalias: "",
           sortOrder: null,
           presentation_type:"direct",
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addNewCategory(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (

            <form className="container" onSubmit={handleSubmit}>
              <div>
                <Backbutton />{" "}
              </div>
              <div className="title">Add New Category</div>
              
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Category Name*"
                      name="categoryname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.categoryname}
                    />
                    {errors.categoryname && (
                      <p style={{ color: "red" }}>{errors.categoryname}</p>
                    )}
                  </div>
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Category slug*"
                      name="categoryalias"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.categoryalias}
                    />
                    {errors.categoryalias && touched.categoryalias && (
                      <p style={{ color: "red" }}>{errors.categoryalias}</p>
                    )}
                    {errortext && <p style={{ color: "red" }}>{errortext}</p>}
                  </div>

                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Sort order*"
                      type="number"
                      name="sortOrder"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sortOrder}
                    />
                    {errors.sortOrder && touched.sortOrder && (
                      <p style={{ color: "red" }}>{errors.sortOrder}</p>
                    )}
                    {errortext && <p style={{ color: "red" }}>{errortext}</p>}
                  </div>

                </div>
              </div>

              <FormControl fullWidth style={{marginTop:30}}>

                          <InputLabel id="demo-simple-select-standard-label" style={{fontSize:17}}>
                             Presentation Type*
                          </InputLabel>

                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.rating_in_stars}
                            onChange={handleChange}
                            label="Presentation Type*"
                            name="presentation_type"
                          >

                            <MenuItem value="direct">Direct</MenuItem>
                            <MenuItem value="color-based">Color Based</MenuItem>
                            <MenuItem value="fitment-based">Fitment Based</MenuItem>
                            
                          </Select>
                        </FormControl>

              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  style={{backgroundColor:'#F05225'}}
                  disabled={!isValid || onLoading}
                  disableElevation
                >
            {onLoading ? <CircularProgress style={{color:'white'}} /> : "Save Category"}

                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(CategoryAdd);
