import { array } from "yup";
import { GET_ROLES, LOGIN, LOGOUT } from "../actions/type";

var initailState = {
  server: "https://backend.arihantauto.in/",
  // server: "http://localhost:8000/",
  auth: false,
  role: {},
};

const authReducer = (state = initailState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        auth: true,
        ...action.payload,
      };
    case LOGOUT:
      return initailState;
    default:
      return state;
  }
};

export default authReducer;