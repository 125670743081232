import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  Box,
  Modal,
} from "@material-ui/core";
import Backbutton from "../../Backbutton";

import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";
import ImageUploader from "react-images-upload";
import { CircularProgress } from '@material-ui/core';

let schema = yup.object({
  brandalias: yup.string().required("Brand Alias is Required"),
  brandname: yup.string().required("Brand Name is Required").typeError(""),
});

function BrandEdit({ server, history, access_token, role, location }) {

  const [errortext, seterrortext] = useState("");
  const [alert, setAlert] = useState(null);
  const [categories, setCategories] = React.useState([]);
  const [image, setImage] = useState(location.state[0].brand_image_path);
  const [open, setOpen] = React.useState(false);
  const [onLoading, setOnLoading] = React.useState(false);
  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {

    getCategories();

    if(location.state[0].category_ids == null){
      setSelectedCategoryIds([])
    }else{
       setSelectedCategoryIds((location?.state[0]?.category_ids).split(','))
    }

    if (role?.id !== 1) {

      if (role?.id === 2) {
        history.push("/Distributer");
      }

      if (role?.id === 3) {
        history.push("/Retailer");
      }

      if (role?.id === 4) {
        history.push("/Games");
      }

    }

  }, []);

  function removeItemOnce(arr, value) {

    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }


  const getCategories = () => {

    fetch(
      'https://backend.arihantauto.in' + "/api/get-all-categories")
      .then((res) => res.json())
      .then((json) => {
        setCategories(json.categories);
      })
  };


  const hideAlert = () => {
    setAlert(null);
  };

  const added = () => {
    setAlert(
      <SweetAlert success title="Updated Successfully" onConfirm={hideAlert}>
        Brand has been updated
      </SweetAlert>
    );

    history.push('/Brand')
  };

  const editValue = (values, resetForm) => {

    setOnLoading(true);

    const bodyParameters = {
      alias: values.brandalias,
      name: values.brandname,
      description: values.description,
      _method: "put",
    };

    const data = new FormData();

    Object.keys(bodyParameters).forEach((key) => {
      data.set(key, bodyParameters[key]);
    });

    if (image && !image.includes("storage")) {
      data.append("brand_image_file", image[0]);
    }

    data.append('category_ids', selectedCategoryIds.join(","))

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://backend.arihantauto.in/' + "api/brand/" + location.state[0].id, data, config)
      .then((response) => {

        if(response.data.hasOwnProperty('message')){

          seterrortext(response.data.message);
          setOnLoading(false);
          
        }else{
          setImage(null);
          seterrortext("");
          resetForm();
          added();
        }

      })
      .catch((error) => {
        const errormessage = error.response.data.errors.alias[0];
        seterrortext(errormessage);
        setOnLoading(false);
      });
  };

  const handleImageChange = (picture) => {
    setImage(picture);
  };

  const handleChangeCheckbox = (e) => {

    if (e.target.checked == true && !(selectedCategoryIds.includes(e.target.name))) {
      var tmp = selectedCategoryIds;
      tmp.push(e.target.name);
      setSelectedCategoryIds(tmp);
    } else {

      var tmp = selectedCategoryIds;
      if (selectedCategoryIds.includes(e.target.name)) {
        tmp = removeItemOnce(tmp, e.target.name);
      }
      setSelectedCategoryIds(tmp);
    }

  }


  return (

    <div className="grayBackgroundBox">

      {alert}

      <div className="card container">

        <Formik
          initialValues={{
            brandname: location?.state[0]?.name,
            brandalias: location?.state[0]?.alias,
            category_ids: location?.state[0]?.category_ids,
            description:
              location?.state[0]?.description == "undefined" || location?.state[0]?.description == "null"
                ? " "
                : location?.state[0]?.description,
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            editValue(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div style={{ textAlign: "start" }}>
                <Backbutton />
              </div>
              <div className="title">Edit Brand</div>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div>
                    <div className="row">
                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          className="inputField"
                          id="standard-basic"
                          label="Brand name*"
                          name="brandname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.brandname}
                        />
                        {errors.brandname && (
                          <p style={{ color: "red" }}>{errors.brandname}</p>
                        )}
                      </div>
                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          className="inputField"
                          id="standard-basic"
                          label="Brand Slug*"
                          name="brandalias"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.brandalias}
                        />
                        {errors.brandalias && touched.brandalias && (
                          <p style={{ color: "red" }}>{errors.brandalias}</p>
                        )}
                        {errortext && (
                          <p style={{ color: "red" }}>{errortext}</p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <p style={{ color: 'grey', marginTop: 30 }}>Applicable Categories</p>

                      <div className="col-12 43poicol-md-6 inputFieldContainer">

                        {categories.map((category, index) => (

                          <div>
                            <input type="checkbox"
                              name={category.id}
                              id={category.id}
                              defaultChecked={selectedCategoryIds.includes(category.id+'')}
                              onChange={handleChangeCheckbox} />
                            <label style={{ fontSize: 12 }} for={category.id}>{category.name}</label>
                          </div>
                        ))}

                      </div>
                    </div>


                    <div className="row">
                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        <TextField
                          className="inputField"
                          id="standard-basic"
                          label="Description"
                          name="description"
                          rows={4}
                          multiline
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                        />
                        {errors.description && touched.description && (
                          <p style={{ color: "red" }}>{errors.description}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                
                <Grid item xs={12} md={6}>

                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Brand Feature Image
                    </Typography>
                    <img
                      src={
                        image != null
                          ? image.includes("storage")
                            ? 'https://backend.arihantauto.in/' + image
                            : URL.createObjectURL(image[0])
                          : image
                      }
                      alt="Brand Image not found"
                      width={"100%"}
                    />
                    <div style={{ textAlign: "center" }}>
                      <Button
                        className="formSubmit"
                        variant="contained"
                        type="button"
                        color="secondary"
                        onClick={handleOpen}
                      >
                        Select Image
                      </Button>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid || onLoading}
                  style={{backgroundColor:'#F05225'}}
                  disableElevation
                >
              {onLoading ? <CircularProgress style={{color:'white'}} /> : "Save Brand"}

                </Button>
              </div>
            </form>
          )}
        </Formik>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "40%",
              minWidth: "400px",
              background: "white",
              padding: "10px 20px",
            }}
          >
            <ImageUploader
              singleImage
              withPreview={true}
              withIcon={true}
              buttonText="Choose Brand Feature Image"
              onChange={handleImageChange}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className="formSubmit"
                variant="contained"
                type="submit"
                color="gray"
                onClick={handleClose}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(BrandEdit);
