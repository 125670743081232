import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, TextareaAutosize } from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core//MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ImageUploader from "react-images-upload";
import FormData from "form-data";
import { Typography, Box, Modal } from "@material-ui/core";
import Selects from "react-select";
import ReactCrop from 'react-image-crop'
import { CircularProgress } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let schema = yup.object({

  disclaimer_text: yup.string().required("Dsiclaimer text is required"),
});


function Settings({
  server,
  history,
  access_token,
  role,
}) {

  const [errortext, seterrortext] = useState("");

  const [disclaimer1, setDisclaimer1] = useState("");
  const [disclaimer2, setDisclaimer2] = useState("");
  const [disclaimer3, setDisclaimer3] = useState("");
  const [disclaimer4, setDisclaimer4] = useState("");
  const [disclaimer5, setDisclaimer5] = useState("");
  const [disclaimer6, setDisclaimer6] = useState("");
  const [disclaimer7, setDisclaimer7] = useState("");
  const [disclaimer8, setDisclaimer8] = useState("");
  const [disclaimer9, setDisclaimer9] = useState("");
  const [disclaimer10, setDisclaimer10] = useState("");

  const [loading, setLoading] = useState("");
  const [onLoading, setOnLoading] = useState("");
  const [onDeletingOrders, setOnDeletingOrders] = useState(false);

  useEffect(() => {

    getDisclaimer();

  }, []);


  const getDisclaimer = () => {

    setLoading(true);

    fetch(
      'https://backend.arihantauto.in' + "/api/get-disclaimer-text")
      .then((res) => res.json())
      .then((json) => {

        setDisclaimer1(json.disclaimerText1)
        setDisclaimer2(json.disclaimerText2)
        setDisclaimer3(json.disclaimerText3)
        setDisclaimer4(json.disclaimerText4)
        setDisclaimer5(json.disclaimerText5)
        setDisclaimer6(json.disclaimerText6)
        setDisclaimer7(json.disclaimerText7)
        setDisclaimer8(json.disclaimerText8)
        setDisclaimer9(json.disclaimerText9)
        setDisclaimer10(json.disclaimerText10)

        setLoading(false);
      })
  };


  const deleteAllOrders = () => {

    setOnDeletingOrders(true);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };

    fetch('https://backend.arihantauto.in' + '/api/user/delete-all-orders', requestOptions)
      .then(response => response.json())
      .then(data => deleteResponse(data.user));
    
  };

  const handleFieldChange = (e) => {

    if (e.target.name == 'disclaimer_text_1') {
      setDisclaimer1(e.target.value);
    }

    if (e.target.name == 'disclaimer_text_2') {
      setDisclaimer2(e.target.value);
    }

    if (e.target.name == 'disclaimer_text_3') {
      setDisclaimer3(e.target.value);
    }

    if (e.target.name == 'disclaimer_text_4') {
      setDisclaimer4(e.target.value);
    }
    if (e.target.name == 'disclaimer_text_5') {
      setDisclaimer5(e.target.value);
    }

    if (e.target.name == 'disclaimer_text_6') {
      setDisclaimer6(e.target.value);
    }

    if (e.target.name == 'disclaimer_text_7') {
      setDisclaimer7(e.target.value);
    }

    if (e.target.name == 'disclaimer_text_8') {
      setDisclaimer8(e.target.value);
    }

    if (e.target.name == 'disclaimer_text_9') {
      setDisclaimer9(e.target.value);
    }

    if (e.target.name == 'disclaimer_text_10') {
      setDisclaimer10(e.target.value);
    }

  }

  const deleteResponse = (e) => {

    setOnDeletingOrders(false);

    toast('Orders data has been cleared', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const saveSettings = (values, resetForm) => {

    setOnLoading(true);

    const bodyParameters = {
      disclaimer_text_1: disclaimer1,
      disclaimer_text_2: disclaimer2,
      disclaimer_text_3: disclaimer3,
      disclaimer_text_4: disclaimer4,
      disclaimer_text_5: disclaimer5,
      disclaimer_text_6: disclaimer6,
      disclaimer_text_7: disclaimer7,
      disclaimer_text_8: disclaimer8,
      disclaimer_text_9: disclaimer9,
      disclaimer_text_10: disclaimer10,
    };

    const data = new FormData();

    Object.keys(bodyParameters).forEach((key) => {
      data.set(key, bodyParameters[key]);
    });

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://backend.arihantauto.in/' + "api/user/update-disclaimer", data, config)
      .then((response) => {
        setOnLoading(false);
        toast('Disclaimer updated', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        const errormessage = error.response.data.errors.alias[0];
        seterrortext(errormessage);
      });
  };

  return (

    <div className="grayBackgroundBox">

      <div className="card container" style={{ marginTop: -100 }}>

        <h1 style={{ marginBottom: 30 }}>Settings</h1>

        <Formik
          initialValues={{
            disclaimer_text: "",
          }}
          onSubmit={(values, { resetForm }) => {
            saveSettings(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>


              <Grid container>
                <Grid item xs={12} md={6}>
                  <div>

                    <div className="row">

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 1*"
                          name="disclaimer_text_1"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer1}
                          fullWidth
                        />

                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 2*"
                          name="disclaimer_text_2"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer2}
                          fullWidth
                        />

                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 3*"
                          name="disclaimer_text_3"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer3}
                          fullWidth
                        />

                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 4*"
                          name="disclaimer_text_4"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer4}
                          fullWidth
                        />

                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 5*"
                          name="disclaimer_text_5"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer5}
                          fullWidth
                        />

                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 6*"
                          name="disclaimer_text_6"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer6}
                          fullWidth
                        />

                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 7*"
                          name="disclaimer_text_7"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer7}
                          fullWidth
                        />

                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 8*"
                          name="disclaimer_text_8"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer8}
                          fullWidth
                        />

                      </div>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 9*"
                          name="disclaimer_text_9"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer9}
                          fullWidth
                        />

                      </div>


                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Disclaimer Text 10*"
                          name="disclaimer_text_10"
                          onChange={handleFieldChange}
                          onBlur={handleBlur}
                          value={disclaimer10}
                          fullWidth
                        />
                      </div>  

                  </div>
                </div>
              </Grid>


              </Grid>
              <div style={{ textAlign: "left" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  style={{ backgroundColor: '#F05225' }}
                  disableElevation
                >
                  {onLoading ? <CircularProgress style={{ color: 'white' }} /> : "Save Settings"}

                </Button>
              </div>
            </form>
          )}
        </Formik>

        <hr style={{ marginTop: 50 }} />

        <h2 style={{ marginTop: 50, }}>Data reset actions</h2>
        <Button
          className="formSubmit"
          variant="contained"
          color="primary"
          style={{ backgroundColor: 'red', paddingTop: 10 }}
          onClick={() => {if(window.confirm('Are you sure to delete all orders? This action cannot be reverted')){ deleteAllOrders()};}} 
          
          disableElevation
        >
          {onDeletingOrders ? <CircularProgress style={{ color: 'white' }} /> : "Delete All Orders"}

        </Button>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(Settings);