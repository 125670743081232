import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, Checkbox, TextareaAutosize } from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core//MenuItem";
import { CircularProgress } from '@material-ui/core';

import SweetAlert from "react-bootstrap-sweetalert";

let schema = yup.object({
  name: yup.string().required("Name is required"),
  username: yup.string().required("Username is required").typeError("Username is invalid"),
  email: yup.string().email().required("Email is required").typeError("Email is invalid"),
  whatsapp_no: yup.string().min(10, 'Please enter 10 digits WhatsApp number').max(10, 'Please enter 10 digits WhatsApp number').required("WhatsApp number is required"),
  phone_no: yup.string().min(10, 'Please enter 10 digits mobile number').max(10, 'Please enter 10 digits mobile number').required("Phone number is required"),
  password: yup.string().required("Password is required").typeError(""),
});

function AddShowroom({ server, history, access_token, role, brands }) {

  const [UsernameError, setUsernameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [PhoneError, setPhoneError] = useState("");
  const [AddressError, setAddressError] = useState("");
  const [StateError, setStateError] = useState("");
  const [DistrictError, setDistrictError] = useState("");
  const [WhatsappError, setWhatsappError] = useState("");
  const [hidePrintOrderError, setHidePrintOrderError] = useState("");
  const [alert, setAlert] = useState(null);
  const [applicableFor, setApplicableFor] = useState([]);
  const [onLoading, setOnLoading] = React.useState(false);

  const hideAlert = () => {
    setAlert(null);
  };

  const dealerTypes = [
    {"id":1, "name":"Authorised Main Dealer",},
    {"id":2, "name":"Associate Dealer",},
    {"id":3, "name":"Authorized Representative of Dealer",},
  ]


  useEffect(() => {
     getAllApplicableFor();
  }, []);


  const getAllApplicableFor = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://backend.arihantauto.in/' + "api/applicable-for", config)
      .then((response) => {
        setApplicableFor(response.data);
      })
      .catch((error) => {});
  }; 

  const added = () => {

    setAlert(
      <SweetAlert
        success
        title="New user created successfully"
        onConfirm={hideAlert}
      >
        Showroom user has been created
      </SweetAlert>
    );

    history.push('/Users');

  };

  const addNewUser = (values, resetForm) => {

    setOnLoading(true);

    const data = {
      name: values.name,
      owner_name: values.owner_name,
      username: values.username,
      email: values.email,
      whatsapp_no: values.whatsapp_no,
      phone_no: values.phone_no,
      password: values.password,
      hide_print_order:values.hidePrintOrderButton,
      address:values.address,
      state:values.state,
      district:values.district,
      dealer_type:values.dealer_type,
      applicable_for:(values.applicable_for).toString()
    };

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://backend.arihantauto.in/' + "api/user/showroom", data, config)
      .then((response) => {
        
        added();
        resetForm();
        setUsernameError("");
        setEmailError("");
        setPhoneError("");
        setWhatsappError("");
        setOnLoading(false);
      })
      .catch((error) => {

        console.log(error.response.data.errors);

        if (error.response.data.errors.hasOwnProperty("username")) {
         // setUsernameError(error.response.data.errors.username[0]);
        }

        if (error.response.data.errors.hasOwnProperty("email")) {
         // setEmailError(error.response.data.errors.email[0]);
        }

        if (error.response.data.errors.hasOwnProperty("phone_no")) {
         // setPhoneError(error.response.data.errors.phone_no[0]);
        }

        if (error.response.data.errors.hasOwnProperty("whatsapp_no")) {
         // setWhatsappError(error.response.data.errors.whatsapp_no[0]);
        }

        if (error.response.data.errors.hasOwnProperty("hidePrintOrder")) {
         // hidePrintOrderError(error.response.data.errors.hide_print_order[0]);
        }

      });
  };

  const brandMenuItems = brands.map((brand) => {
    return <MenuItem value={brand.id}>{brand.name}</MenuItem>;
  });

  const applicableForMenuItems = applicableFor.map((applicableForItem) => {
    return <MenuItem value={applicableForItem.id}>{applicableForItem.name}</MenuItem>;
  });

  const dealerValues = dealerTypes.map((dealer) => {
    return <MenuItem value={dealer.id}>{dealer.name}</MenuItem>;
  });

  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">

        <Formik
          initialValues={{
            name: "",
            owner_name:"",
            username: "",
            email: "",
            whatsapp_no: "",
            phone_no: "",
            password: "",
            hide_print_order:"",
            dealer_type:"",
            address:"",
            state:"",
            district:"",
            applicable_for:[],
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addNewUser(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div>
                <Backbutton />{" "}
              </div>
              <div className="title">Add Showroom User</div>
              
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      id="standard-basic"
                      label="Billing Name*"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      fullWidth
                    />
                    {errors.name && (
                      <p style={{ color: "red" }}>{errors.name}</p>
                    )}
                  </div>

                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      id="standard-basic"
                      label="Owner Name"
                      name="owner_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.owner_name}
                      fullWidth
                    />
                    {errors.owner_name && (
                      <p style={{ color: "red" }}>{errors.owner_name}</p>
                    )}
                  </div>


                  <div className="col-12 col-md-6 inputFieldContainer">
                    
                    <TextField
                      id="standard-basic"
                      label="Username*"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      fullWidth
                    />
                    {errors.username && touched.username && (
                      <p style={{ color: "red" }}>{errors.username}</p>
                    )}
                    {UsernameError && (
                      <p style={{ color: "red" }}>{UsernameError}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Email"
                      name="email"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    )}
                    {EmailError && <p style={{ color: "red" }}>{EmailError}</p>}
                  </div>
                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Whatsapp Number"
                      
                      name="whatsapp_no"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.whatsapp_no}
                    />
                     {errors.whatsapp_no && touched.whatsapp_no && (
                      <p style={{ color: "red" }}>{errors.whatsapp_no}</p>
                    )}
                    {WhatsappError && (
                      <p style={{ color: "red" }}>{WhatsappError}</p>
                    )}
                  </div>

                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Phone"
                      name="phone_no"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone_no}
                    />
                    {errors.phone_no && touched.phone_no && (
                      <p style={{ color: "red" }}>{errors.phone_no}</p>
                    )}
                    {PhoneError && <p style={{ color: "red" }}>{PhoneError}</p>}
                  </div>


                  <div className="col-12 43poicol-md-6 inputFieldContainer" style={{marginTop:20}}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Address"
                      multiline={true}
                      name="address"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {errors.address && touched.address && (
                      <p style={{ color: "red" }}>{errors.address}</p>
                    )}
                    {AddressError && <p style={{ color: "red" }}>{AddressError}</p>}
                  </div>

                  <div className="col-12 43poicol-md-6 inputFieldContainer" style={{marginTop:20}}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="State"
                      multiline={true}
                      name="state"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                    />
                    {errors.state && touched.state && (
                      <p style={{ color: "red" }}>{errors.state}</p>
                    )}
                    {StateError && <p style={{ color: "red" }}>{StateError}</p>}
                  </div>

                  <div className="col-12 43poicol-md-6 inputFieldContainer" style={{marginTop:20}}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="District"
                      multiline={true}
                      name="district"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.district}
                    />
                    {errors.district && touched.district && (
                      <p style={{ color: "red" }}>{errors.district}</p>
                    )}
                    {DistrictError && <p style={{ color: "red" }}>{DistrictError}</p>}
                  </div>                  


                  <div className="col-12 43poicol-md-6 inputFieldContainer" style={{marginTop:20}}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">
                        Applicable For
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-multiple-name"
                        value={values.applicable_for}
                        onChange={handleChange}
                        multiple
                        label="Applicable For"
                        name="applicable_for"
                      >
                       
                        {applicableForMenuItems}
                      </Select>
                    </FormControl>

                    {errors.applicable_for && touched.applicable_for && (
                      <p style={{ color: "red" }}>{errors.applicable_for}</p>
                    )}
                  </div>



                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">
                        Dealer Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-multiple-name"
                        value={values.dealer_type}
                        onChange={handleChange}
                        label="Dealer Type"
                        name="dealer_type"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {dealerValues}
                      </Select>
                    </FormControl>

                    {errors.dealer_type && touched.dealer_type && (
                      <p style={{ color: "red" }}>{errors.dealer_type}</p>
                    )}
                  </div>



                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      fullWidth
                      id="standard-password-input"
                      type="password"
                      autoComplete="current-password"
                      name="password"
                      label="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </div>


                  <div className="col-6 col-md-3" style={{marginTop:20}}>
                    <Checkbox
                      className=""
                      id="hide-print-order-button"
                      name="hidePrintOrderButton"
                      label="Hide Print Order Button"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.hidePrintOrderButton}
                    />
                    <label for="hide-print-order-button">Hide Print Order Button</label>
                    {errors.hidePrintOrderButton && touched.hidePrintOrderButton && (
                      <p style={{ color: "red" }}>{errors.hidePrintOrderButton}</p>
                    )}
                  </div>


                </div>
              </div>
              
              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid || onLoading}
                  style={{backgroundColor:'#F05225'}}
                  disableElevation
                >
                 {onLoading ? <CircularProgress style={{color:'white'}} /> : "Save User"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  brands: state.brand,
});

export default connect(mapStateToProps, {})(AddShowroom);
