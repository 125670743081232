import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, TextareaAutosize } from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core//MenuItem";
import FormControl from "@material-ui/core/FormControl";
//import Select from "@material-ui/core/Select";
import Select from 'react-select';

import ImageUploader from "react-images-upload";
import FormData from "form-data";
import { Typography, Box, Modal } from "@material-ui/core";
import Selects from "react-select";
import ReactCrop from 'react-image-crop'
import "./Inventory.css";
import { CircularProgress } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { MultiSelect } from "react-multi-select-component";


let schema = yup.object({

  name: yup.string().required("Product name is required"),
  name_in_hindi: yup.string().required("Product name in Hindi is required"),
  tally_import_name: yup.string().required("Tally import name is required"),
  brand_id: yup.string().required("Brand is required").typeError(""),
  applicable_for: yup.array().min(1).required("Applicable for is required").typeError(""),
  price: yup
    .number()
    .required("Price is required")
    .typeError("Number is required"),
});

function InventoryAdd({
  server,
  history,
  access_token,
  role,
}) {

  const [errortext, seterrortext] = useState("");
  const [alert, setAlert] = useState(null);
  const [image, setImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState(0);
  const [category, setCategory] = React.useState({});
  const [applicableFor, setApplicableFor] = useState([]);
  const [productBucket, setProductBucket] = useState([]);
  const [fitments, setFitments] = useState([]);
  const [onLoading, setOnLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false); 

  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  const [selectedBrand, setSelectedBrand] = React.useState(null);
  const [brandOptions, setBrandOptions] = React.useState([]);

  const [selectedProductBucket, setSelectedProductBucket] = React.useState(null);
  const [productBucketOptions, setProductBucketOptions] = React.useState([]);


  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [image6, setImage6] = useState(null);
  const [image7, setImage7] = useState(null);
  const [image8, setImage8] = useState(null);
  const [image9, setImage9] = useState(null);
  const [image10, setImage10] = useState(null);

  const [image1ToSubmit, setImage1ToSubmit] = useState(null);
  const [image2ToSubmit, setImage2ToSubmit] = useState(null);
  const [image3ToSubmit, setImage3ToSubmit] = useState(null);
  const [image4ToSubmit, setImage4ToSubmit] = useState(null);
  const [image5ToSubmit, setImage5ToSubmit] = useState(null);
  const [image6ToSubmit, setImage6ToSubmit] = useState(null);
  const [image7ToSubmit, setImage7ToSubmit] = useState(null);
  const [image8ToSubmit, setImage8ToSubmit] = useState(null);
  const [image9ToSubmit, setImage9ToSubmit] = useState(null);
  const [image10ToSubmit, setImage10ToSubmit] = useState(null);  

  const [selected, setSelected] = useState([]);

  const [crop, setCrop] = useState({ aspect: 16 / 9 })

  const [Color, setColor] = useState([
    "None",
    "Pink",
    "Crimson",
    "Red",
    "Maroon",
    "Brown",
    "Misty Rose",
    "Salmon",
    "Coral",
    "Orange-Red",
    "Chocolate",
    "Orange",
    "Gold",
    "Ivory",
    "Yellow",
    "Olive",
    "Yellow-Green",
    "Lawn green",
    "Chartreuse",
    "Lime",
    "Green",
    "Spring green",
    "Aquamarine",
    "Turquoise",
    "Azure",
    "Aqua/Cyan",
    "Teal",
    "Lavender",
    "Blue",
    "Navy",
    "Blue-Violet",
    "Indigo",
    "Dark Violet",
    "Plum",
    "Magenta",
    "Purple",
    "Red-Violet",
    "Tan",
    "Beige",
    "Slate gray",
    "Dark Slate Gray",
    "White",
    "White Smoke",
    "Light Gray",
    "Silver",
    "Dark Gray",
    "Gray",
    "Dim Gray",
    "Black",
  ]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const applicableForMenuItems = applicableFor.map((applicableForItem) => {
    return <MenuItem value={applicableForItem.id}>{applicableForItem.name}</MenuItem>;
  });

  useEffect(() => {

    if (role?.id !== 1) {
      if (role?.id === 2) {
        history.push("/Distributer");
      }
      if (role?.id === 3) {
        history.push("/Retailer");
      }
      if (role?.id === 4) {
        history.push("/Games");
      }
    }

    getCategories();
    getBrands();
    getAllApplicableFor();
    getAllProductBuckets();
    getFitments();

  }, []); 



  const hideAlert = () => {
    setAlert(null);
  };

  const getFitments = () => {

    fetch(
      'https://backend.arihantauto.in' + "/api/get-all-fitments")
      .then((res) => res.json())
      .then((json) => {
        setFitments(json.fitments);        
      })
  };

  const setImages = (e) => {

    if (e.target.name == 'image1') {

      const [file] = e.target.files;
      setImage1(URL.createObjectURL(file));
      setImage1ToSubmit(file);

    } else if (e.target.name == 'image2') {

      const [file] = e.target.files;
      setImage2(URL.createObjectURL(file));
      setImage2ToSubmit(file);

    } else if (e.target.name == 'image3') {

      const [file] = e.target.files;
      setImage3(URL.createObjectURL(file));
      setImage3ToSubmit(file);

    } else if (e.target.name == 'image4') {

      const [file] = e.target.files;
      setImage4(URL.createObjectURL(file));
      setImage4ToSubmit(file);

    } else if (e.target.name == 'image5') {

      const [file] = e.target.files;
      setImage5(URL.createObjectURL(file));
      setImage5ToSubmit(file);

    } else if (e.target.name == 'image6') {

      const [file] = e.target.files;
      setImage6(URL.createObjectURL(file));
      setImage6ToSubmit(file);

    } else if (e.target.name == 'image7') {

      const [file] = e.target.files;
      setImage7(URL.createObjectURL(file));
      setImage7ToSubmit(file);

    } else if (e.target.name == 'image8') {

      const [file] = e.target.files;
      setImage8(URL.createObjectURL(file));
      setImage8ToSubmit(file);

    } else if (e.target.name == 'image9') {

      const [file] = e.target.files;
      setImage9(URL.createObjectURL(file));
      setImage9ToSubmit(file);

    } else if (e.target.name == 'image10') {

      const [file] = e.target.files;
      setImage10(URL.createObjectURL(file));
      setImage10ToSubmit(file);
    }
  }


  const getAllApplicableFor = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://backend.arihantauto.in/' + "api/applicable-for", config)
      .then((response) => {        

        var tempArray = [];
        for (var i = 0; i < response.data.length; i++) {

          var obj = {};
          obj.label = response.data[i].name;
          obj.value = response.data[i].id;
          tempArray.push(obj);
        }

        setApplicableFor(tempArray)

      })
      .catch((error) => { });
  };

  const getAllProductBuckets = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://backend.arihantauto.in/' + "api/product-bucket", config)
      .then((response) => {
        setProductBucket(response.data);

        var productBucketOptionsTmp = [];

        productBucketOptionsTmp.push({value:0, label:'None'});

        for(var i=0;i<response.data.length;i++){

          var item = {};
          item.value = response.data[i].id;
          item.label = response.data[i].name;

          productBucketOptionsTmp.push(item);
        }

        setProductBucketOptions(productBucketOptionsTmp);

        setLoading(false);
      })
      .catch((error) => { });
  };


  const added = () => {

    setAlert(
      <SweetAlert success title="Product Added" onConfirm={hideAlert}>
        Product created
      </SweetAlert>
    );

    history.push('/Inventory')
  };

  const getCategories = () => {

    setLoading(true);

    fetch(
      'https://backend.arihantauto.in' + "/api/get-all-categories")
      .then((res) => res.json())
      .then((json) => {
        
        setCategories(json.categories);

        var categoryOptionsTmp = [];

        for(var i=0;i<json.categories.length;i++){
          var item = {};
          item.value = json.categories[i].id;
          item.label = json.categories[i].name;

          categoryOptionsTmp.push(item);
        }

        setCategoryOptions(categoryOptionsTmp);

      })

  };

  const getBrands = () => {

    fetch(
      'https://backend.arihantauto.in' + "/api/get-all-brands")
      .then((res) => res.json())
      .then((json) => {

        setBrands(json.brands);

        var brandOptionsTmp = [];

        for(var i=0;i<json.brands.length;i++){
          var item = {};
          item.value = json.brands[i].id;
          item.label = json.brands[i].name;

          brandOptionsTmp.push(item);
        }

        setBrandOptions(brandOptionsTmp);
      })
  };


  const addNewInventory = (values, resetForm) => {  
   
    setOnLoading(true);

    var applicableForTemp = [];

    for (var i = 0; i < selected.length; i++) {

      applicableForTemp.push(selected[i].value)
    }

    const bodyParameters = {
      size: values.size,
      model: values.model,
      variant: values.variant,
      name: values.name,
      name_in_hindi: values.name_in_hindi,
      tally_import_name: values.tally_import_name,
      product_video_youtube_code: values.product_video_youtube_code,
      color: values.color,
      //brand_id: values.brand_id,
      //product_bucket: values.product_bucket,
      price: values.price,
      description: values.description,
      //category_id: categoryId,
      fitment_for: values.fitment_for,
      filters_applicable: values.filters_applicable,
      rating_in_stars: values.rating_in_stars,
      is_new_product: values.is_new_product,
      is_upcoming_product: values.is_upcoming_product,
      positioning: values.positioning,
      status: values.status,
      applicable_for: applicableForTemp.toString(),
      category_id:selectedCategory.value,
      brand_id:selectedBrand.value,
      product_bucket:selectedProductBucket.value,
    };    
   

    const data = new FormData();

    Object.keys(bodyParameters).forEach((key) => {
      data.set(key, bodyParameters[key]);
    });

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    data.append("category_id", selectedCategory.value);
    data.append("brand_id", selectedBrand.value);
    data.append("product_bucket", selectedProductBucket.value);

    if (image1) {
      data.append("product_image_file", image1ToSubmit);
    }

    if (image2) {
      data.append("product_image_file2", image2ToSubmit);
    }

    if (image3) {
      data.append("product_image_file3", image3ToSubmit);
    }

    if (image4) {
      data.append("product_image_file4", image4ToSubmit);
    }

    if (image5) {
      data.append("product_image_file5", image5ToSubmit);
    }

    if (image6) {
      data.append("product_image_file6", image6ToSubmit);
    }

    if (image7) {
      data.append("product_image_file7", image7ToSubmit);
    }

    if (image8) {
      data.append("product_image_file8", image8ToSubmit);
    }

    if (image9) {
      data.append("product_image_file9", image9ToSubmit);
    }

    if (image10) {
      data.append("product_image_file10", image10ToSubmit);
    }   


    axios
      .post('https://backend.arihantauto.in/' + "api/inventory", data, config)
      .then((response) => {
        console.log(response);
        added();
        resetForm();
        seterrortext("");
        setOnLoading(false);
      })
      .catch((error) => {
        const errormessage = error.response.data.errors.alias[0];
        seterrortext(errormessage);
      }); 

    
  };

  const handleImageChange = (picture) => {
    setImage(picture);
  };

  const handleCategoryChange = (selectedOption) => {    

    setSelectedCategory(selectedOption)  

    for(var i=0;i<categories.length;i++){

        if(parseInt(categories[i].id) == parseInt(selectedOption.value)){            
          setCategory(categories[i]);      
        }
    }
  };


  const handleBrandChange = (selectedOption) => {

    setSelectedBrand(selectedOption);    
  };

  const handleProductBucketChange = (selectedOption) => {

    setSelectedProductBucket(selectedOption);   
  };

  var filteredBrands = [];

  for (var i = 0; i < brands.length; i++) {
    filteredBrands.push(brands[i]);
  }

  const brandMenuItems = filteredBrands.map((brand) => {
    return <MenuItem value={brand.id}>{brand.name}</MenuItem>;
  });

  const categoriesMenuItems = categories.map((category) => {
    return <MenuItem value={category.id}>{category.name}</MenuItem>;
  });

  var theProductBuckets = [{ id: 0, name: 'None' }];
  for (var i = 0; i < productBucket.length; i++) {
    theProductBuckets.push(productBucket[i])
  }

  const productBucketMenuItems = theProductBuckets.map((productBucketItem) => {
    return <MenuItem value={productBucketItem.id}>{productBucketItem.name}</MenuItem>;
  });


  return (
    <div className="grayBackgroundBox">
      {alert}

      <div className="card container">

        <Formik
          initialValues={{
            name: "",
            name_in_hindi: "",
            tally_import_name: "",
            size: "None",
            model:"",
            variant: "",
            description: "",
            color: "",
            price: "",
            fitment_for: "",
            brand_id: "",
            product_bucket: "",
            category_id: "",
            is_new_product: "Yes",
            is_upcoming_product: "Yes",
            applicable_for: [],
            rating_in_stars:"1",
            positioning:"Top",
            status:"enabled",
            filters_applicable:"Not Applicable",
            product_video_youtube_code:"",
          }}
          

          validate={(values, props) => {          

            const errors = {};            

            if(selectedCategory==null){
              errors.category = 'Category is required';
            }

            if(selectedBrand==null){
              errors.brand = 'Brand is required';
            }

            if(selectedProductBucket==null){
              errors.product_bucket = 'Product bucket is required';
            }
            

            if(!values.name){
              errors.name = 'Product name is required';
            }            

            if(category.presentation_type == 'fitment-based'){

              if(!values.fitment_for){
                errors.fitment_for = 'Fitment for is required';
              }
            }

            if(category.name == 'Helmets' || category.name == 'Branded helmet'){
                if(!values.variant){
                  errors.variant = 'Variant is required';
                }
            }

            if(!values.name_in_hindi){
              errors.name_in_hindi = 'Product name in Hindi is required';
            }

            if(!values.tally_import_name){
              errors.tally_import_name = 'Tally import name is required';
            }

            if(!values.price){
              errors.price = 'Price is required';
            }          
          
            return errors;

          }}
          
          onSubmit={(values, { resetForm }) => {                    

              addNewInventory(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div>
                <Backbutton />
              </div>

              <div className="title">Add New Inventory</div>

              <Grid container>
                <Grid item xs={12} md={6}>
                  <div>
                    <div className="col-12 43poicol-md-6 inputFieldContainer">
                      
                        <InputLabel id="demo-simple-select-standard-label" style={{marginBottom:10}}>
                          Category*
                        </InputLabel>                        

                        <Select
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          options={categoryOptions}
                          placeholder="Select Category"                          
                        />                         
                   

                        {errors.category && (
                            <p style={{ color: "red" }}>{errors.category}</p>
                          )}
                    </div>

                    <div className="row">

                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                      <InputLabel id="demo-simple-select-standard-label" style={{marginBottom:10, marginTop:20}}>
                          Brand*
                      </InputLabel>    

                        <Select
                          value={selectedBrand}
                          onChange={handleBrandChange}
                          options={brandOptions}
                          placeholder="Select Brand"
                        />  

                        {errors.brand && (
                            <p style={{ color: "red" }}>{errors.brand}</p>
                          )}

                      
                      </div>


                      <div className="col-12 43poicol-md-6 inputFieldContainer">                        

                      <InputLabel id="demo-simple-select-standard-label" style={{marginBottom:10, marginTop:20}}>
                          Product Bucket*
                        </InputLabel>    


                        <Select
                          value={selectedProductBucket}
                          onChange={handleProductBucketChange}
                          options={productBucketOptions}
                          placeholder="Select Product Bucket"
                        />  

                        {errors.product_bucket && (
                            <p style={{ color: "red" }}>{errors.product_bucket}</p>
                        )}


                      </div>                    


                      <div className="col-12 43poicol-md-6 inputFieldContainer" style={{ marginTop: 20 }}>
                      

                          <InputLabel id="demo-simple-select-standard-label" style={{marginBottom:10, marginTop:20}}>
                            Applicable For* 
                          </InputLabel>

                        <MultiSelect
                          options={applicableFor}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select Applicable For"
                          placeholder="Select Applicable For"
                        />

                        {errors.applicable_for && touched.applicable_for && (
                          <p style={{ color: "red" }}>Applicable for is required</p>
                        )}
                      
                    </div>


                      <div className="row">

                        <div className="col-12 col-md-6 inputFieldContainer">
                          <TextField
                            id="standard-basic"
                            label="Product Name*"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            fullWidth
                          />
                          {errors.name && touched.name && (
                            <p style={{ color: "red" }}>{errors.name}</p>
                          )}
                          {errortext && (
                            <p style={{ color: "red" }}>{errortext}</p>
                          )}
                        </div>
                      </div>


                      <div className="row">

                        <div className="col-12 col-md-6 inputFieldContainer">
                          <TextField
                            id="standard-basic"
                            label="Product Name in Hindi*"
                            name="name_in_hindi"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name_in_hindi}
                            fullWidth
                          />
                          {errors.name_in_hindi && touched.name_in_hindi && (
                            <p style={{ color: "red" }}>{errors.name_in_hindi}</p>
                          )}
                          {errortext && (
                            <p style={{ color: "red" }}>{errortext}</p>
                          )}
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-12 col-md-6 inputFieldContainer">
                          <TextField
                            id="standard-basic"
                            label="Tally import name*"
                            name="tally_import_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tally_import_name}
                            fullWidth
                          />
                          {errors.tally_import_name && touched.tally_import_name && (
                            <p style={{ color: "red" }}>{errors.tally_import_name}</p>
                          )}
                          {errortext && (
                            <p style={{ color: "red" }}>{errortext}</p>
                          )}
                        </div>
                      </div>


                      <div className="row">

                        <div className="col-12 col-md-6 inputFieldContainer">
                          <TextField
                            id="standard-basic"
                            label="Product video youtube code (e.g. 2YBtspm8j8M)"
                            name="product_video_youtube_code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.product_video_youtube_code}
                            fullWidth
                          />
                          {errors.product_video_youtube_code && touched.product_video_youtube_code && (
                            <p style={{ color: "red" }}>{errors.product_video_youtube_code}</p>
                          )}
                          {errortext && (
                            <p style={{ color: "red" }}>{errortext}</p>
                          )}
                        </div>
                      </div>



                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Size
                          </InputLabel>
                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.size}
                            onChange={handleChange}
                            label="Size"
                            name="size"
                            className="form-control"
                          >

                            <option value="None" selected>None</option>
                            <option value="Small">Small</option>
                            <option value="Medium">Medium</option>
                            <option value="Large">Large</option>
                          </select>
                       
                        {errors.size && touched.size && (
                          <p style={{ color: "red" }}>{errors.size}</p>
                        )}
                      </div>


                      <div className="col-12 col-md-6 inputFieldContainer">
                          <TextField
                            id="standard-basic"
                            label="Model"
                            name="model"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.model}
                            fullWidth
                          />
                          {errors.model && touched.model && (
                            <p style={{ color: "red" }}>{errors.model}</p>
                          )}
                          {errortext && (
                            <p style={{ color: "red" }}>{errortext}</p>
                          )}
                      </div>
                      <p style={{ color: 'green', fontSize: 13 }}>Enter a comma separated list of models for creating bulk
                            entries of model variant products</p>

                      {(category.name == 'Helmets' || category.name == 'Branded helmet') &&
                        <div className="col-12 43poicol-md-6 inputFieldContainer">
                          
                            <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                              Variant*
                            </InputLabel>
                            <select
                              labelId="demo-simple-select-standard-label"
                              id="demo-multiple-name"
                              value={values.variant}
                              onChange={handleChange}
                              label="Variant*"
                              name="variant"
                              className="form-control"
                            >
                              <option value="None">None</option>
                              <option value="Full Face">Full Face</option>
                              <option value="Flip">Flip Face</option>
                              <option value="Half Face">Half Face</option>                              
                              <option value="Motocross">Motocross</option>
                            </select>
                         
                          {errors.variant && touched.variant && (
                            <p style={{ color: "red" }}>{errors.variant}</p>
                          )}
                        </div>
                      }

                    

                    {(category.presentation_type == 'fitment-based') &&
                        <div className="col-12 43poicol-md-6 inputFieldContainer">                         

                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Fitment For*
                          </InputLabel>

                          

                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.fitment_for}                            
                            onChange={handleChange}
                            label="Fitment for*"
                            name="fitment_for"
                            className="form-control"
                          >
                            <option value="">Select an option</option>
                                {fitments.map((fitment, index) => (
                                    <option value={fitment.fitment_name}>{fitment.fitment_name}</option>
                                ))}                                  

                          </select>

                          
                          
                          {errors.fitment_for && touched.fitment_for && (
                            <p style={{ color: "red" }}>{errors.fitment_for}</p>
                          )}
                        </div>
                      }


                      <div className="col-12 43poicol-md-6 inputFieldContainer">

                        <FormControl fullWidth>

                          <TextField
                            id="standard-basic"
                            label="Color"
                            name="color"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.color}
                            fullWidth
                          />

                          <p style={{ color: 'green', fontSize: 13 }}>Enter a comma separated list of colors for create bulk
                            entries of color variant products e.g. Red, Green, Blue, Orange</p>

                          <p style={{ color: 'blue', fontSize: 13 }}>USE COMMA SEPARATED VALUES IN ONLY ONE OF THE COLOR AND MODEL FIELDS, NOT IN BOTH.</p>


                        </FormControl>
                        {errors.color && touched.color && (
                          <p style={{ color: "red" }}>{errors.color}</p>
                        )}
                      </div>

                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        <TextField
                          style={{
                            minWidth: "400px",
                            marginTop: "0px",
                          }}
                          label="Price*"
                          name="price"
                          id="standard-basic"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.price}
                          fullWidth
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                        {errors.price && touched.price && (
                          <p style={{ color: "red" }}>{errors.price}</p>
                        )}
                      </div>


                      

                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Rating (number of stars)*
                          </InputLabel>

                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.rating_in_stars}
                            onChange={handleChange}
                            label="Rating (number of stars)*"
                            name="rating_in_stars"
                            className="form-control"
                          >

                            <option value="1" selected>1</option>
                            <option value="1.5">1.5</option>
                            <option value="2">2</option>
                            <option value="2.5">2.5</option>
                            <option value="3">3</option>
                            <option value="3.5">3.5</option>
                            <option value="4">4</option>
                            <option value="4.5">4.5</option>
                            <option value="5">5</option>
                          </select>
                        
                        {errors.rating_in_stars && touched.rating_in_stars && (
                          <p style={{ color: "red" }}>{errors.rating_in_stars}</p>
                        )}
                      </div>



                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Is New Product?*
                          </InputLabel>

                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.is_new_product}
                            onChange={handleChange}
                            label="Is New Product*"
                            name="is_new_product"
                            className="form-control"
                          >

                            <option value="yes" selected>Yes</option>
                            <option value="no">No</option>

                          </select>
                        
                        {errors.is_new_product && touched.is_new_product && (
                          <p style={{ color: "red" }}>{errors.is_new_product}</p>
                        )}
                      </div>


                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Is Upcoming Product?*
                          </InputLabel>
                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.is_upcoming_product}
                            onChange={handleChange}
                            label="Is Upcoming Product*"
                            name="is_upcoming_product"
                            className="form-control"
                          >

                            <option value="yes" selected>Yes</option>
                            <option value="no">No</option>

                          </select>
                        
                        {errors.is_upcoming_product && touched.is_upcoming_product && (
                          <p style={{ color: "red" }}>{errors.v}</p>
                        )}
                      </div>



                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Positioning*
                          </InputLabel>
                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.positioning}
                            onChange={handleChange}
                            label="Positioning*"
                            name="positioning"
                            className="form-control"
                          >

                            <option value="Top" selected>Top</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>

                          </select>
                        
                        {errors.positioning && touched.positioning && (
                          <p style={{ color: "red" }}>{errors.positioning}</p>
                        )}
                      </div>

                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Status*
                          </InputLabel>

                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.status}
                            onChange={handleChange}
                            label="Status*"
                            name="status"
                            className="form-control"
                          >

                            <option value="enabled" selected>Enabled</option>
                            <option value="disabled">Disabled</option>

                          </select>
                        
                        {errors.status && touched.status && (
                          <p style={{ color: "red" }}>{errors.status}</p>
                        )}
                      </div>

                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Filters Applicable*
                          </InputLabel>
                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.filters_applicable}
                            onChange={handleChange}
                            label="Filters Applicable*"
                            name="filters_applicable"
                            className="form-control"
                          >

                            <option value="Not Applicable" selected>Not Applicable</option>
                            <option value="BS4">BS4</option>
                            <option value="BS6">BS6</option>
                            <option value="Slim">Slim</option>
                            <option value="Wide">Wide</option>

                          </select>
                        
                        {errors.filters_applicable && touched.filters_applicable && (
                          <p style={{ color: "red" }}>{errors.filters_applicable}</p>
                        )}
                      </div>


                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        <TextField
                          label="Description"
                          name="description"
                          // className="inputField"
                          id="standard-basic"
                          rows={4}
                          multiline
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          fullWidth
                        />
                        {errors.description && touched.description && (
                          <p style={{ color: "red" }}>{errors.description}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>

                  <h3 style={{ textAlign: 'center', marginTop: 30 }}>
                    Product images
                  </h3>
                  <p style={{ textAlign: 'center', marginTop: 0, color:'red' }}>(Only webp images are allowed)</p>

                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image1 != null) ? image1 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 1 (default picture)</label>
                      <input type="file" name="image1" id="image1" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>

                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image2 != null) ? image2 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 2</label>
                      <input type="file" name="image2" id="image2" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image3 != null) ? image3 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 3</label>
                      <input type="file" name="image3" id="image3" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image4 != null) ? image4 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 4</label>
                      <input type="file" name="image4" id="image4" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image5 != null) ? image5 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 5</label>
                      <input type="file" name="image5" id="image5" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>

                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image6 != null) ? image6 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 6</label>
                      <input type="file" name="image6" id="image6" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image7 != null) ? image7 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 7</label>
                      <input type="file" name="image7" id="image7" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image8 != null) ? image8 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 8</label>
                      <input type="file" name="image8" id="image8" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image9 != null) ? image9 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 9</label>
                      <input type="file" name="image9" id="image9" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    <img
                      src={(image10 != null) ? image10 : ""}
                      width={200}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 10</label>
                      <input type="file" name="image10" id="image10" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>

                </Grid>
              </Grid>
              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  style={{ backgroundColor: '#F05225' }}
                  disabled={onLoading}
                  disableElevation
                >
                  {onLoading ? <CircularProgress style={{ color: 'white' }} /> : "Save Product"}

                </Button>
              </div>
            </form>
          )}
        </Formik>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "40%",
              minWidth: "400px",
              background: "white",
              padding: "10px 20px",
            }}
          >

            <ImageUploader
              singleImage={false}
              withPreview={true}
              withIcon={true}
              buttonText="Choose product feature images"
              onChange={handleImageChange}
              imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className="formSubmit"
                variant="contained"
                type="submit"
                color="gray"
                onClick={handleClose}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal>
      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(InventoryAdd);
