import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Grid,
  TextareaAutosize,
  Typography,
  Box,
  Modal,
} from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import { CircularProgress } from '@material-ui/core';

import SweetAlert from "react-bootstrap-sweetalert";
import ImageUploader from "react-images-upload";
import FormData from "form-data";

let schema = yup.object({
  applicableforname: yup.string().required("Name is Required").typeError(""),
});

function ApplicableForAdd({ server, history, access_token, role }) {

  const [errortext, seterrortext] = useState("");
  const [alert, setAlert] = useState(null);
  const [image, setImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [onLoading, setOnLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (role?.id !== 1) {
      if (role?.id === 2) {
        history.push("/Distributer");
      }
      if (role?.id === 3) {
        history.push("/Retailer");
      }
      if (role?.id === 4) {
        history.push("/Games");
      }
    }
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  const added = () => {
    setAlert(
      <SweetAlert success title="Added" onConfirm={hideAlert}>
        Applicable For has been created
      </SweetAlert>
    );
    history.push('/ApplicableFor')
  };

  const addNewApplicableFor = (values, resetForm) => {

    setOnLoading(true);

    const bodyParameters = {
      name: values.applicableforname,
      description: values.description,
    };

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    const data = new FormData();

    Object.keys(bodyParameters).forEach((key) => {
      data.set(key, bodyParameters[key]);
    });
   

    axios
      .post('https://backend.arihantauto.in/' + "api/applicable-for", data, config)
      .then((response) => {
       
        added();
        resetForm();
        setImage(null);
        seterrortext("");
        setOnLoading(false);
      })
      .catch((error) => {
        const errormessage = error.response.data.errors.alias[0];
        seterrortext(errormessage);
      });
  };

  const handleImageChange = (picture) => {
    setImage(picture);
  };

  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <Formik
          initialValues={{
            applicableforname: "",            
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            addNewApplicableFor(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div>
                <Backbutton />
              </div>
              <div className="title">Add New Applicable For</div>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div>
                    <div className="row">
                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          className="inputField"
                          id="standard-basic"
                          label="Applicable for name*"
                          name="applicableforname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.applicableforname}
                        />
                        {errors.applicableforname && (
                          <p style={{ color: "red" }}>{errors.applicableforname}</p>
                        )}
                      </div>
                      
                      </div>

                  </div>
                </Grid>
                
              </Grid>
              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid || onLoading}
                  style={{backgroundColor:'#F05225'}}
                  disableElevation
                >
                  {onLoading ? <CircularProgress style={{color:'white'}} /> : "Save Applicable For"}                  
                </Button>
              </div>
            </form>
          )}
        </Formik>
        
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(ApplicableForAdd);
