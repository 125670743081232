import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, TextareaAutosize } from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core//MenuItem";
import FormControl from "@material-ui/core/FormControl";
//import Select from "@material-ui/core/Select";
import Select from 'react-select';
import ImageUploader from "react-images-upload";
import FormData from "form-data";
import { Typography, Box, Modal } from "@material-ui/core";
import Selects from "react-select";
import "./Inventory.css";
import { CircularProgress } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { MultiSelect } from "react-multi-select-component";


let schema = yup.object({
  name: yup.string().required("Name is required"),
  name_in_hindi: yup.string().required("Product name in Hindi is required"),
  tally_import_name: yup.string().required("Tally import name is required"),
  brand_id: yup.string().required("Brand is required").typeError(""),
  category_id: yup.string().required("Category is required").typeError(""),
  price: yup
    .number()
    .required("Price is required")
    .typeError("Number is required"),

});

function InventoryAdd({
  server,
  history,
  access_token,
  role,
  location
}) {

  const [errortext, seterrortext] = useState("");
  const [alert, setAlert] = useState(null);
  const [onLoading, setOnLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fitments, setFitments] = useState([]);
  const [applicableForArray, setApplicableForArray] = React.useState([]);
  const [category, setCategory] = React.useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const [image, setImage] = useState([location.state[0].product_image_path,

  location.state[0].product_image_path2,
  location.state[0].product_image_path3,
  location.state[0].product_image_path4,
  location.state[0].product_image_path5,
  location.state[0].product_image_path6,
  location.state[0].product_image_path7,
  location.state[0].product_image_path8,
  location.state[0].product_image_path9,
  location.state[0].product_image_path10]);


  const [image1, setImage1] = useState(location.state[0].product_image_path);
  const [image2, setImage2] = useState(location.state[0].product_image_path2);
  const [image3, setImage3] = useState(location.state[0].product_image_path3);
  const [image4, setImage4] = useState(location.state[0].product_image_path4);
  const [image5, setImage5] = useState(location.state[0].product_image_path5);
  const [image6, setImage6] = useState(location.state[0].product_image_path6);
  const [image7, setImage7] = useState(location.state[0].product_image_path7);
  const [image8, setImage8] = useState(location.state[0].product_image_path8);
  const [image9, setImage9] = useState(location.state[0].product_image_path9);
  const [image10, setImage10] = useState(location.state[0].product_image_path10);

  const [image1ToSubmit, setImage1ToSubmit] = useState(null);
  const [image2ToSubmit, setImage2ToSubmit] = useState(null);
  const [image3ToSubmit, setImage3ToSubmit] = useState(null);
  const [image4ToSubmit, setImage4ToSubmit] = useState(null);
  const [image5ToSubmit, setImage5ToSubmit] = useState(null);
  const [image6ToSubmit, setImage6ToSubmit] = useState(null);
  const [image7ToSubmit, setImage7ToSubmit] = useState(null);
  const [image8ToSubmit, setImage8ToSubmit] = useState(null);
  const [image9ToSubmit, setImage9ToSubmit] = useState(null);
  const [image10ToSubmit, setImage10ToSubmit] = useState(null);

  const [open, setOpen] = React.useState(false);

  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  const [selectedBrand, setSelectedBrand] = React.useState(null);
  const [brandOptions, setBrandOptions] = React.useState([]);

  const [selectedProductBucket, setSelectedProductBucket] = React.useState(null);
  const [productBucketOptions, setProductBucketOptions] = React.useState([]);

  const [Color, setColor] = useState([
    "Pink",
    "Crimson",
    "Red",
    "Maroon",
    "Brown",
    "Misty Rose",
    "Salmon",
    "Coral",
    "Orange-Red",
    "Chocolate",
    "Orange",
    "Gold",
    "Ivory",
    "Yellow",
    "Olive",
    "Yellow-Green",
    "Lawn green",
    "Chartreuse",
    "Lime",
    "Green",
    "Spring green",
    "Aquamarine",
    "Turquoise",
    "Azure",
    "Aqua/Cyan",
    "Teal",
    "Lavender",
    "Blue",
    "Navy",
    "Blue-Violet",
    "Indigo",
    "Dark Violet",
    "Plum",
    "Magenta",
    "Purple",
    "Red-Violet",
    "Tan",
    "Beige",
    "Slate gray",
    "Dark Slate Gray",
    "White",
    "White Smoke",
    "Light Gray",
    "Silver",
    "Dark Gray",
    "Gray",
    "Dim Gray",
    "Black",
  ]);

  const [categories, setCategories] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState(0);

  const [applicableFor, setApplicableFor] = useState([]);
  const [productBucket, setProductBucket] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = useState([]);


  const handleDeleteImage = async (imageNum) => {

    if (!isDeleting && image2) {
      const confirmDelete = window.confirm('Are you sure you want to delete this image?');

      if (confirmDelete) {

        setIsDeleting(true);

        const config = {
           headers: { Authorization: `Bearer ${access_token}` },
        };

        const inventory_id = location.state[0].id; 
        const image_number = imageNum; 

        try {
          // Call the API to delete the image
          await axios.post('https://backend.arihantauto.in/' + '/api/user/deleteProductImage', {
            inventory_id,
            image_number,
          }, config);

          // On successful response, update the state and set image2 to null
          setIsDeleting(false);
          
          if(image_number == 2){
            setImage2(null);
          }else if(image_number == 3){
            setImage3(null);
          }else if(image_number == 4){
            setImage4(null);
          }else if(image_number == 5){
            setImage5(null);
          }else if(image_number == 6){
            setImage6(null);
          }else if(image_number == 7){
            setImage7(null);
          }else if(image_number == 8){
            setImage8(null);
          }else if(image_number == 9){
            setImage9(null);
          }else if(image_number == 10){
            setImage10(null);
          }


        } catch (error) {

          
          setIsDeleting(false);

          if(image_number == 2){
            setImage2(null);
          }else if(image_number == 3){
            setImage3(null);
          }else if(image_number == 4){
            setImage4(null);
          }else if(image_number == 5){
            setImage5(null);
          }else if(image_number == 6){
            setImage6(null);
          }else if(image_number == 7){
            setImage7(null);
          }else if(image_number == 8){
            setImage8(null);
          }else if(image_number == 9){
            setImage9(null);
          }else if(image_number == 10){
            setImage10(null);
          }
          
        }
      }
    }
  };


  useEffect(() => {

    if (role?.id !== 1) {
      if (role?.id === 2) {
        history.push("/Distributer");
      }
      if (role?.id === 3) {
        history.push("/Retailer");
      }
      if (role?.id === 4) {
        history.push("/Games");
      }
    }

    getCategories();
    getBrands();
    getAllApplicableFor();
    getAllProductBuckets();
    getFitments();

  }, []);



  const getFitments = () => {

    fetch(
      'https://backend.arihantauto.in' + "/api/get-all-fitments")
      .then((res) => res.json())
      .then((json) => {
        setFitments(json.fitments);        
      })

  }; 


  useEffect(() => {

    var alreadySelectedOnes = location.state[0].applicable_for.split(',');

    var tmpSelected = [];

    for (var i = 0; i < applicableFor.length; i++) {

      for (var j = 0; j < alreadySelectedOnes.length; j++) {

        if (alreadySelectedOnes[j] == applicableFor[i].value) {

          var obj = {};
          obj.value = parseInt(alreadySelectedOnes[j]);
          obj.label = applicableFor[i].label;
          tmpSelected.push(obj)
        }
      }
    }

    setSelected(tmpSelected);

  }, [applicableFor]);


  const getCategories = () => {

    setLoading(true);

    fetch(
      'https://backend.arihantauto.in' + "/api/get-all-categories")
      .then((res) => res.json())
      .then((json) => {

        setTheCategories(json.categories)

        var categoryOptionsTmp = [];

        for(var i=0;i<json.categories.length;i++){
          
          var item = {};
          item.value = json.categories[i].id;
          item.label = json.categories[i].name;

          categoryOptionsTmp.push(item);

          // Set the existing catgeory
          if(location.state[0]?.category_id == json.categories[i].id){
            setSelectedCategory(item);
          }          

        }

        setCategoryOptions(categoryOptionsTmp);        

      })

  };

  const setTheCategories = (categories) => {

    setCategories(categories)
 
    for(var i=0;i<categories.length;i++){

       if(parseInt(categories[i].id) == parseInt(location.state[0]?.category_id)){            
           setCategory(categories[i]);      
       }
    }
  }


  function removeItemOnce(arr, value) {

    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }


  const getBrands = () => {

    fetch(
      'https://backend.arihantauto.in' + "/api/get-all-brands")
      .then((res) => res.json())
      .then((json) => {

        setBrands(json.brands)

        var brandOptionsTmp = [];

        for(var i=0;i<json.brands.length;i++){
          
          var item = {};
          item.value = json.brands[i].id;
          item.label = json.brands[i].name;

          brandOptionsTmp.push(item);

          // Set the existing brand
          if(location.state[0]?.brand_id == json.brands[i].id){
            setSelectedBrand(item);
          }
        }

        setBrandOptions(brandOptionsTmp);        

      })
  };


  const setImages = (e) => {

    if (e.target.name == 'image1') {

      const [file] = e.target.files;
      setImage1(URL.createObjectURL(file));
      setImage1ToSubmit(file);

    } else if (e.target.name == 'image2') {

      const [file] = e.target.files;
      setImage2(URL.createObjectURL(file));
      setImage2ToSubmit(file);

    } else if (e.target.name == 'image3') {

      const [file] = e.target.files;
      setImage3(URL.createObjectURL(file));
      setImage3ToSubmit(file);

    } else if (e.target.name == 'image4') {

      const [file] = e.target.files;
      setImage4(URL.createObjectURL(file));
      setImage4ToSubmit(file);

    } else if (e.target.name == 'image5') {

      const [file] = e.target.files;
      setImage5(URL.createObjectURL(file));
      setImage5ToSubmit(file);

    } else if (e.target.name == 'image6') {

      const [file] = e.target.files;
      setImage6(URL.createObjectURL(file));
      setImage6ToSubmit(file);

    } else if (e.target.name == 'image7') {

      const [file] = e.target.files;
      setImage7(URL.createObjectURL(file));
      setImage7ToSubmit(file);

    } else if (e.target.name == 'image8') {

      const [file] = e.target.files;
      setImage8(URL.createObjectURL(file));
      setImage8ToSubmit(file);

    } else if (e.target.name == 'image9') {

      const [file] = e.target.files;
      setImage9(URL.createObjectURL(file));
      setImage9ToSubmit(file);

    } else if (e.target.name == 'image10') {

      const [file] = e.target.files;
      setImage10(URL.createObjectURL(file));
      setImage10ToSubmit(file);
    }
  }

  const hideAlert = () => {
    setAlert(null);
  };

  const added = () => {
    setAlert(
      <SweetAlert success title="Updated Successfully" onConfirm={hideAlert}>
        Product has been updated
      </SweetAlert>
    );

    history.push('/Inventory')
  };

  const applicableForMenuItems = applicableFor.map((applicableForItem) => {
    return <MenuItem value={applicableForItem.id}>{applicableForItem.name}</MenuItem>;
  });


  const getAllApplicableFor = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://backend.arihantauto.in/' + "api/applicable-for", config)
      .then((response) => {

        //setApplicableFor(response.data);

        var tempArray = [];
        for (var i = 0; i < response.data.length; i++) {

          var obj = {};
          obj.label = response.data[i].name;
          obj.value = response.data[i].id;
          tempArray.push(obj);
        }

        setApplicableFor(tempArray)

      })
      .catch((error) => { });
  };



  const getAllProductBuckets = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .get('https://backend.arihantauto.in/' + "api/product-bucket", config)
      .then((response) => {

        setProductBucket(response.data);

        var productBucketOptionsTmp = [];

        productBucketOptionsTmp.push({value:0, label:'None'});

        setSelectedProductBucket({value:0, label:'None'});

        for(var i=0;i<response.data.length;i++){

          var item = {};
          item.value = response.data[i].id;
          item.label = response.data[i].name;

          productBucketOptionsTmp.push(item);
          
          // Set the existing product bucket
          if(location.state[0]?.product_bucket == response.data[i].id){
            setSelectedProductBucket(item);
          }
        }

        setProductBucketOptions(productBucketOptionsTmp);

        setLoading(false);
      })
      .catch((error) => { });
  };


  const addNewInventory = (values, resetForm) => {

    setOnLoading(true);

    var applicableForTemp = [];

    for (var i = 0; i < selected.length; i++) {

      applicableForTemp.push(selected[i].value)
    }


    const bodyParameters = {

      size: values.size,
      model: values.model,
      variant: values.variant,
      name: values.name,
      name_in_hindi: values.name_in_hindi,
      tally_import_name: values.tally_import_name,
      product_video_youtube_code: values.product_video_youtube_code,
      color: values.color,
      price: values.price,
      description: values.description,
      positioning: values.positioning,
      status: values.status,
      filters_applicable: values.filters_applicable,
      category_id: values.category_id,
      //brand_id: values.brand_id,
      //product_bucket: values.product_bucket,
      fitment_for: values.fitment_for,
      rating_in_stars: values.rating_in_stars,
      is_new_product: values.is_new_product,
      is_upcoming_product: values.is_upcoming_product,
      applicable_for: applicableForTemp.toString(),
      _method: "put"
    };

    const data = new FormData();

    Object.keys(bodyParameters).forEach((key) => {
      data.set(key, bodyParameters[key]);
    });

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    
    data.append("brand_id", selectedBrand.value);
    data.append("product_bucket", selectedProductBucket.value);
    //data.append("category_id", selectedProductBucket.value);


    if (image1 && !image1.includes("storage")) {
      data.append("product_image_file", image1ToSubmit);
    }

    if (image2 && !image2.includes("storage")) {
      data.append("product_image_file2", image2ToSubmit);
    }

    if (image3 && !image3.includes("storage")) {
      data.append("product_image_file3", image3ToSubmit);
    }

    if (image4 && !image4.includes("storage")) {
      data.append("product_image_file4", image4ToSubmit);
    }

    if (image5 && !image5.includes("storage")) {
      data.append("product_image_file5", image5ToSubmit);
    }

    if (image6 && !image6.includes("storage")) {
      data.append("product_image_file6", image6ToSubmit);
    }

    if (image7 && !image7.includes("storage")) {
      data.append("product_image_file7", image7ToSubmit);
    }

    if (image8 && !image8.includes("storage")) {
      data.append("product_image_file8", image8ToSubmit);
    }

    if (image9 && !image9.includes("storage")) {
      data.append("product_image_file9", image9ToSubmit);
    }

    if (image10 && !image10.includes("storage")) {
      data.append("product_image_file10", image10ToSubmit);
    }

    axios
      .post('https://backend.arihantauto.in/' + "api/inventory/" + location.state[0]?.id, data, config)
      .then((response) => {

        added();
        resetForm();
        seterrortext("");
        setOnLoading(false);
      })
      .catch((error) => {
        const errormessage = error.response.data.errors.alias[0];
        seterrortext(errormessage);
      });
  };

  const handleImageChange = (picture) => {
    setImage(picture);
  };

  const handleCategoryChange = (selectedOption) => {    

    setSelectedCategory(selectedOption)  

    for(var i=0;i<categories.length;i++){

        if(parseInt(categories[i].id) == parseInt(selectedOption.value)){            
          setCategory(categories[i]);      
        }
    }
  };

  const handleBrandChange = (selectedOption) => {

    setSelectedBrand(selectedOption);    
  };

  const handleProductBucketChange = (selectedOption) => {

    setSelectedProductBucket(selectedOption);   
  };

  const brandMenuItems = brands.map((brand) => {
    return <MenuItem value={brand.id}>{brand.name}</MenuItem>;
  });

  const categoriesMenuItems = categories.map((category) => {
    return <MenuItem value={category.id}>{category.name}</MenuItem>;
  });

  var theProductBuckets = [{ id: 0, name: 'None' }];
  for (var i = 0; i < productBucket.length; i++) {
    theProductBuckets.push(productBucket[i])
  }

  const productBucketMenuItems = theProductBuckets.map((productBucketItem) => {
    return <MenuItem value={productBucketItem.id}>{productBucketItem.name}</MenuItem>;
  });


  return (
    <div className="grayBackgroundBox">
      {alert}

      <div className="card container">

        <Formik
          initialValues={{
            name: location.state[0]?.name,
            name_in_hindi: location.state[0]?.name_in_hindi,
            product_video_youtube_code: location.state[0].product_video_youtube_code,
            tally_import_name: location.state[0].tally_import_name,
            size: location.state[0]?.size,
            model: location.state[0]?.model,
            variant: location.state[0]?.variant,
            description: location.state[0]?.description,
            rating_in_stars: location.state[0]?.rating_in_stars,
            positioning: location.state[0]?.positioning,
            filters_applicable: location.state[0]?.filters_applicable,
            status: location.state[0]?.status,
            color: location.state[0]?.color,
            price: location.state[0]?.price,
            fitment_for: location.state[0]?.fitment_for,
            is_new_product: location.state[0].is_new_product && location.state[0].is_new_product == 1 ? 'yes' : 'no',
            is_upcoming_product: location.state[0].is_upcoming_product && location.state[0].is_upcoming_product == 1 ? 'yes' : 'no',
            //category_id: location.state[0]?.category_id,
            //brand_id: location.state[0]?.brand_id,
            //product_bucket: location.state[0].product_bucket,
            applicable_for: [],
          }}
          //validationSchema={schema}
          validate={(values, props) => {          

            const errors = {};            

            if(selectedCategory==null){
              errors.category = 'Category is required';
            }

            if(selectedBrand==null){
              errors.brand = 'Brand is required';
            }

            if(selectedProductBucket==null){
              errors.product_bucket = 'Product bucket is required';
            }
            

            if(!values.name){
              errors.name = 'Product name is required';
            }            

            if(category.presentation_type == 'fitment-based'){

              if(!values.fitment_for){
                errors.fitment_for = 'Fitment for is required';
              }
            }

            if(category.name == 'Helmets' || category.name == 'Branded helmet'){
                if(!values.variant){
                  errors.variant = 'Variant is required';
                }
            }

            if(!values.name_in_hindi){
              errors.name_in_hindi = 'Product name in Hindi is required';
            }

            if(!values.tally_import_name){
              errors.tally_import_name = 'Tally import name is required';
            }

            if(!values.price){
              errors.price = 'Price is required';
            }          
          
            return errors;

          }}
          onSubmit={(values, { resetForm }) => {
            addNewInventory(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            <form className="container" onSubmit={handleSubmit}>
              <div>
                <Backbutton />
              </div>
              <div className="title">Editing Product</div>
              <Grid container>
                <Grid item xs={12} md={6}>

                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                    
                      <InputLabel id="demo-simple-select-standard-label" style={{marginBottom:10, marginTop:20}}>
                        Category*
                      </InputLabel>

                      <Select
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          options={categoryOptions}
                          placeholder="Select Category"      
                          isDisabled={true}                    
                        />                  
                    

                    {errors.category_id && touched.category_id && (
                      <p style={{ color: "red" }}>{errors.category_id}</p>
                    )}
                  </div>

                  <div>

                    <div className="col-12 43poicol-md-6 inputFieldContainer">
                      
                        <InputLabel id="demo-simple-select-standard-label" style={{marginBottom:10, marginTop:20}}>
                          Brand*
                        </InputLabel>
                        <Select
                          value={selectedBrand}
                          onChange={handleBrandChange}
                          options={brandOptions}
                          placeholder="Select Brand"
                        />  
                      

                      {errors.brand_id && touched.brand_id && (
                        <p style={{ color: "red" }}>{errors.brand_id}</p>
                      )}
                    </div>


                    <div className="col-12 43poicol-md-6 inputFieldContainer">
                      
                        <InputLabel id="demo-simple-select-standard-label" style={{marginBottom:10, marginTop:20}}>
                          Product Bucket*
                        </InputLabel>
                        <Select
                          value={selectedProductBucket}
                          onChange={handleProductBucketChange}
                          options={productBucketOptions}
                          placeholder="Select Product Bucket"
                        />                       

                      {errors.product_bucket && touched.product_bucket && (
                        <p style={{ color: "red" }}>{errors.product_bucket}</p>
                      )}
                    </div>

                    <div className="col-12 43poicol-md-6 inputFieldContainer" style={{ marginTop: 20 }}>
                      <FormControl fullWidth>

                        <label>Applicable for</label>

                        <MultiSelect
                          options={applicableFor}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select Applicable For"
                        />
                      </FormControl>
                    </div>


                    <div className="row" style={{ marginTop: 10 }}>

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Name*"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          fullWidth
                        />
                        {errors.name && touched.name && (
                          <p style={{ color: "red" }}>{errors.name}</p>
                        )}
                        {errortext && (
                          <p style={{ color: "red" }}>{errortext}</p>
                        )}
                      </div>
                    </div>

                    <div className="row">

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Product Name in Hindi*"
                          name="name_in_hindi"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name_in_hindi}
                          fullWidth
                        />
                        {errors.name_in_hindi && touched.name_in_hindi && (
                          <p style={{ color: "red" }}>{errors.name_in_hindi}</p>
                        )}
                        {errortext && (
                          <p style={{ color: "red" }}>{errortext}</p>
                        )}
                      </div>
                    </div>


                    <div className="row">

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Tally import name*"
                          name="tally_import_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tally_import_name}
                          fullWidth
                        />
                        {errors.tally_import_name && touched.tally_import_name && (
                          <p style={{ color: "red" }}>{errors.tally_import_name}</p>
                        )}
                        {errortext && (
                          <p style={{ color: "red" }}>{errortext}</p>
                        )}
                      </div>
                    </div>


                    <div className="row">

                      <div className="col-12 col-md-6 inputFieldContainer">
                        <TextField
                          id="standard-basic"
                          label="Product video youtube code (e.g. 2YBtspm8j8M)"
                          name="product_video_youtube_code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.product_video_youtube_code 
                            && values.product_video_youtube_code!==null 
                            && values.product_video_youtube_code!=='null'
                            && values.product_video_youtube_code !=='undefined'?values.product_video_youtube_code:'' }
                          fullWidth
                        />
                        {errors.product_video_youtube_code && touched.product_video_youtube_code && (
                          <p style={{ color: "red" }}>{errors.product_video_youtube_code}</p>
                        )}
                        {errortext && (
                          <p style={{ color: "red" }}>{errortext}</p>
                        )}
                      </div>
                    </div>

                    <div className="row">

                    {(category.name == 'Helmets' || category.name == 'Branded helmet') &&
                        <div className="col-12 43poicol-md-6 inputFieldContainer">
                          
                            <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                              Variant*
                            </InputLabel>
                            <select
                              labelId="demo-simple-select-standard-label"
                              id="demo-multiple-name"
                              value={values.variant && values.variant!=='null'?values.variant:'None'}
                              onChange={handleChange}
                              label="Variant*"
                              name="variant"
                              className="form-control"
                            >
                              <option value="None">None</option>
                              <option value="Full Face">Full Face</option>
                              <option value="Flip">Flip Face</option>
                              <option value="Half Face">Half Face</option>                              
                              <option value="Motocross">Motocross</option>
                            </select>
                         
                          {errors.variant && touched.variant && (
                            <p style={{ color: "red" }}>{errors.variant}</p>
                          )}
                        </div>
                      }


                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                        <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                          Size
                        </InputLabel>
                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-multiple-name"
                          value={values.size}
                          onChange={handleChange}
                          label="Size"
                          name="size"
                          className="form-control"
                        >

                          <option value="None" selected>None</option>
                          <option value="Small">Small</option>
                          <option value="Medium">Medium</option>
                          <option value="Large">Large</option>
                        </select>
                     
                      {errors.size && touched.size && (
                        <p style={{ color: "red" }}>{errors.size}</p>
                      )}
                    </div>


                      <div className="col-12 col-md-6 inputFieldContainer">
                          <TextField
                            id="standard-basic"
                            label="Model"
                            name="model"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.model && values.model!=='null'?values.model:''}
                            fullWidth
                          />
                          {errors.model && touched.model && (
                            <p style={{ color: "red" }}>{errors.model}</p>
                          )}
                          {errortext && (
                            <p style={{ color: "red" }}>{errortext}</p>
                          )}
                      </div>



                      {(category.presentation_type == 'fitment-based') &&
                        <div className="col-12 43poicol-md-6 inputFieldContainer">                         

                          <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                            Fitment For*
                          </InputLabel>

                          

                          <select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            value={values.fitment_for}                            
                            onChange={handleChange}
                            label="Fitment for*"
                            name="fitment_for"
                            className="form-control"
                          >
                            <option value="">Select an option</option>
                                {fitments.map((fitment, index) => (
                                    <option value={fitment.fitment_name}>{fitment.fitment_name}</option>
                                ))}                                  

                          </select>

                          
                          
                          {errors.fitment_for && touched.fitment_for && (
                            <p style={{ color: "red" }}>{errors.fitment_for}</p>
                          )}
                        </div>
                      }


                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        <FormControl fullWidth>

                          <TextField
                            id="standard-basic"
                            label="Color*"
                            name="color"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.color}
                            fullWidth
                          />


                          <p style={{ color: 'green', fontSize: 13 }}>Enter single color here. Do not enter a comma separated list</p>
                        </FormControl>
                        {errors.color && touched.color && (
                          <p style={{ color: "red" }}>{errors.color}</p>
                        )}
                      </div>
                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        <TextField
                          style={{
                            minWidth: "400px",
                            marginTop: "0px",
                          }}
                          label="Price*"
                          name="price"
                          id="standard-basic"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.price}
                          fullWidth
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                        {errors.price && touched.price && (
                          <p style={{ color: "red" }}>{errors.price}</p>
                        )}
                      </div>





                      <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                        <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                          Rating (number of stars)*
                        </InputLabel>

                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-multiple-name"
                          value={values.rating_in_stars}
                          onChange={handleChange}
                          label="Rating (number of stars)*"
                          name="rating_in_stars"
                          className="form-control"
                        >

                          <option value="1" selected>1</option>
                          <option value="1.5">1.5</option>
                          <option value="2">2</option>
                          <option value="2.5">2.5</option>
                          <option value="3">3</option>
                          <option value="3.5">3.5</option>
                          <option value="4">4</option>
                          <option value="4.5">4.5</option>
                          <option value="5">5</option>
                        </select>
                      
                      {errors.rating_in_stars && touched.rating_in_stars && (
                        <p style={{ color: "red" }}>{errors.rating_in_stars}</p>
                      )}
                    </div>



                    <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                        <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                          Is New Product?*
                        </InputLabel>

                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-multiple-name"
                          value={values.is_new_product}
                          onChange={handleChange}
                          label="Is New Product*"
                          name="is_new_product"
                          className="form-control"
                        >

                          <option value="yes" selected>Yes</option>
                          <option value="no">No</option>

                        </select>
                      
                      {errors.is_new_product && touched.is_new_product && (
                        <p style={{ color: "red" }}>{errors.is_new_product}</p>
                      )}
                    </div>


                    <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                        <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                          Is Upcoming Product?*
                        </InputLabel>
                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-multiple-name"
                          value={values.is_upcoming_product}
                          onChange={handleChange}
                          label="Is Upcoming Product*"
                          name="is_upcoming_product"
                          className="form-control"
                        >

                          <option value="yes" selected>Yes</option>
                          <option value="no">No</option>

                        </select>
                      
                      {errors.is_upcoming_product && touched.is_upcoming_product && (
                        <p style={{ color: "red" }}>{errors.v}</p>
                      )}
                    </div>



                    <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                        <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                          Positioning*
                        </InputLabel>
                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-multiple-name"
                          value={values.positioning}
                          onChange={handleChange}
                          label="Positioning*"
                          name="positioning"
                          className="form-control"
                        >

                          <option value="Top" selected>Top</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>

                        </select>
                      
                      {errors.positioning && touched.positioning && (
                        <p style={{ color: "red" }}>{errors.positioning}</p>
                      )}
                    </div>

                    <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                        <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                          Status*
                        </InputLabel>

                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-multiple-name"
                          value={values.status}
                          onChange={handleChange}
                          label="Status*"
                          name="status"
                          className="form-control"
                        >

                          <option value="enabled" selected>Enabled</option>
                          <option value="disabled">Disabled</option>

                        </select>
                      
                      {errors.status && touched.status && (
                        <p style={{ color: "red" }}>{errors.status}</p>
                      )}
                    </div>

                    <div className="col-12 43poicol-md-6 inputFieldContainer">
                        
                        <InputLabel id="demo-simple-select-standard-label" style={{marginTop:10, marginBottom:5}}>
                          Filters Applicable*
                        </InputLabel>
                        <select
                          labelId="demo-simple-select-standard-label"
                          id="demo-multiple-name"
                          value={values.filters_applicable}
                          onChange={handleChange}
                          label="Filters Applicable*"
                          name="filters_applicable"
                          className="form-control"
                        >

                          <option value="Not Applicable" selected>Not Applicable</option>
                          <option value="BS4">BS4</option>
                          <option value="BS6">BS6</option>
                          <option value="Slim">Slim</option>
                          <option value="Wide">Wide</option>

                        </select>
                      
                      {errors.filters_applicable && touched.filters_applicable && (
                        <p style={{ color: "red" }}>{errors.filters_applicable}</p>
                      )}
                    </div>



                      <div className="col-12 43poicol-md-6 inputFieldContainer"
                        style={{ marginTop: 20 }}>
                        <TextField
                          label="Description"
                          name="description"
                          id="standard-basic"
                          rows={4}
                          multiline
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          fullWidth
                        />
                        {errors.description && touched.description && (
                          <p style={{ color: "red" }}>{errors.description}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>


                <Grid item xs={12} md={6}>

                  <h3 style={{ textAlign: 'center', marginTop: 30 }}>
                    Product images
                  </h3>
                  <p style={{ textAlign: 'center', marginTop: 0, color:'red' }}>(Only webp images are allowed)</p>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image1 &&
                      <img
                        src={
                          image1
                            ? image1.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image1
                              : image1
                            : image1
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 1 (Default picture)</label>
                      <input type="file" name="image1" id="image1" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                    </div>
                  </Box>

                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image2 &&
                      <img
                        src={
                          image2
                            ? image2.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image2
                              : image2
                            : image2
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 2</label>
                      <input type="file" name="image2" id="image2" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />
                        {/* Delete button with trash bin icon */}
                        {image2 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(2)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image3 &&
                      <img
                        src={
                          image3
                            ? image3.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image3
                              : image3
                            : image3
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 3</label>
                      <input type="file" name="image3" id="image3" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />

{image3 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(3)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }

                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image4 &&
                      <img
                        src={
                          image4
                            ? image4.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image4
                              : image4
                            : image4
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 4</label>
                      <input type="file" name="image4" id="image4" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />

{image4 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(4)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image5 &&
                      <img
                        src={
                          image5
                            ? image5.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image5
                              : image5
                            : image5
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 5</label>
                      <input type="file" name="image5" id="image5" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />

{image5 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(5)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }
                    </div>
                  </Box>

                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image6 &&
                      <img
                        src={
                          image6
                            ? image6.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image6
                              : image6
                            : image6
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 6</label>
                      <input type="file" name="image6" id="image6" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />

{image6 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(6)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }

                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image7 &&
                      <img
                        src={
                          image7
                            ? image7.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image7
                              : image7
                            : image7
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 7</label>
                      <input type="file" name="image7" id="image7" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />

{image7 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(7)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image8 &&
                      <img
                        src={
                          image8
                            ? image8.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image8
                              : image8
                            : image8
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 8</label>
                      <input type="file" name="image8" id="image8" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />

{image8 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(8)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image9 &&
                      <img
                        src={
                          image9
                            ? image9.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image9
                              : image9
                            : image9
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 9</label>
                      <input type="file" name="image9" id="image9" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />

{image9 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(9)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }
                    </div>
                  </Box>


                  <Box sx={{ p: 2, border: "1px dashed grey", mt: 5, ml: 5 }}>

                    {image10 &&
                      <img
                        src={
                          image10
                            ? image10.includes("storage")
                              ? 'https://backend.arihantauto.in/' + image10
                              : image10
                            : image10
                        }
                        width={200}
                      />
                    }

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label>Image 10</label>
                      <input type="file" name="image10" id="image10" onChange={setImages} accept="image/webp"
                        style={{ marginTop: 10 }} />

      {image10 &&
            <button
              disabled={isDeleting}
              onClick={() => handleDeleteImage(10)} 
              style={{
                marginTop: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                color: isDeleting ? 'gray' : 'red',
                alignSelf:'flex-start'
              }}
            >
              Delete
            </button>
         }
                    </div>
                  </Box>

                </Grid>
              </Grid>
              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid}
                  disableElevation
                  disabled={onLoading}
                  style={{ backgroundColor: '#F05225' }}
                >
                  {onLoading ? <CircularProgress style={{ color: 'white' }} /> : "Save Product"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "40%",
              minWidth: "400px",
              background: "white",
              padding: "10px 20px",
            }}
          >
            <ImageUploader
              singleImage={false}
              withPreview={true}
              withIcon={true}
              buttonText="Choose Product Feature Image"
              onChange={handleImageChange}
              imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className="formSubmit"
                variant="contained"
                type="submit"
                color="gray"
                onClick={handleClose}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(InventoryAdd);
