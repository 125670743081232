import React, { Fragment, useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import Dashboard from "./components/views/dashboard/Dashboard";
import Inventory from "./components/views/Inventory/Inventory";
import Brand from "./components/views/Brand/Brand";
import ApplicableFor from "./components/views/ApplicableFor/ApplicableFor";
import FitmentFor from "./components/views/FitmentFor/FitmentFor";
import ProductBucket from "./components/views/ProductBucket/ProductBucket";
import CreateOrder from "./components/views/Orderss/CreateOrder";
import EditOrder from "./components/views/Orderss/EditOrder";
import AddGeneric from "./components/views/Users/AddGeneric";
import AddShowroom from "./components/views/Users/AddShowroom";
import "./assets/scss/App.scss";
import { Router, Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Login from "./components/login/Login";
import PrivateRoute from "./privateRoute";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import factory from "./store";
import BrandAdd from "./components/views/Brand/BrandAdd";
import ApplicableForAdd from "./components/views/ApplicableFor/ApplicableForAdd";
import FitmentForAdd from "./components/views/FitmentFor/FitmentForAdd";
import ProductBucketAdd from "./components/views/ProductBucket/ProductBucketAdd";

import InventoryAdd from "./components/views/Inventory/InventoryAdd";
import BrandEdit from "./components/views/Brand/BrandEdit";
import ApplicableForEdit from "./components/views/ApplicableFor/ApplicableForEdit";
import FitmentForEdit from "./components/views/FitmentFor/FitmentForEdit";
import ProductBucketEdit from "./components/views/ProductBucket/ProductBucketEdit";

import inventoryedit from "./components/views/Inventory/inventoryedit";
import Category from "./components/views/Category/Category";
import Settings from "./components/views/Settings/Settings";
import CategoryAdd from "./components/views/Category/CategoryAdd";
import CategoryEdit from "./components/views/Category/CategoryEdit";
import Users from "./components/views/Users/Users";
import EditGeneric from "./components/views/Users/EditGeneric";
import EditShowroom from "./components/views/Users/EditShowroom";
import ChangePassword from "./components/views/ChangePassword";
import Order from "./components/views/Orderss/Order";
import Uploady from "@rpldy/uploady";


import {  Link, useHistory } from 'react-router-dom';

const { store, persistor } = factory();

const hist = createBrowserHistory();

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
});


export default function App() {

  const history = useHistory();

  history.listen(() => {
    window.scrollTo(0, 0);
  });


  const [cacheCleared, setCacheCleared] = useState(false);

  const classes = useStyles();
  global.backendUrlNew = 'https://backend.arihantauto.in';
  

  return (

    <Provider store={store}>

    <ToastContainer />

      <PersistGate loading={null} persistor={persistor}>
        <Router history={hist}>

          <Switch>
            {/* SideBar Routes */}
            <Route path="/" component={Login} exact={true} />
            <PrivateRoute
              path="/Dashboard"
              component={Dashboard}
              exact={true}
            />
            <PrivateRoute path="/Brand" exact component={Brand} />

            <PrivateRoute path="/ApplicableFor" exact component={ApplicableFor} />
            <PrivateRoute path="/FitmentFor" exact component={FitmentFor} />
            <PrivateRoute path="/ProductBucket" exact component={ProductBucket} />


            <PrivateRoute
              path="/inventory/edit"
              exact
              component={inventoryedit}
            />
            <PrivateRoute path="/Inventory" exact component={Inventory} />
          <PrivateRoute path="/Order" exact component={Order}/>
          <PrivateRoute path="/create-order" exact component={CreateOrder}/>
          <PrivateRoute path="/edit-order" exact component={EditOrder}/>

            <PrivateRoute
              path="/User/AddGeneric"
              exact
              component={AddGeneric}
            />
            <PrivateRoute path="/Password" exact component={ChangePassword} />
            <PrivateRoute
              path="/User/AddShowroom"
              exact
              component={AddShowroom}
            />
                        <PrivateRoute
              path="/User/Generic/Edit"
              exact
              component={EditGeneric}
            />
            <PrivateRoute
              path="/User/Showroom/Edit"
              exact
              component={EditShowroom}
            />
            <PrivateRoute path="/Brand/Add" exact component={BrandAdd} />
            <PrivateRoute path="/Brand/Edit" exact component={BrandEdit} />
            <PrivateRoute path="/ApplicableFor/Add" exact component={ApplicableForAdd} />
            <PrivateRoute path="/ApplicableFor/Edit" exact component={ApplicableForEdit} />
            <PrivateRoute path="/FitmentFor/Add" exact component={FitmentForAdd} />
            <PrivateRoute path="/FitmentFor/Edit" exact component={FitmentForEdit} />
            <PrivateRoute path="/ProductBucket/Add" exact component={ProductBucketAdd} />
            <PrivateRoute path="/ProductBucket/Edit" exact component={ProductBucketEdit} />
            <PrivateRoute
              path="/Inventory/Add"
              exact
              component={InventoryAdd}
            />
            <PrivateRoute path="/Users" exact component={Users} />
            <PrivateRoute path="/Category" exact component={Category} />
            <PrivateRoute path="/Category/Add" exact component={CategoryAdd} />
            <PrivateRoute
              path="/Category/Edit"
              exact
              component={CategoryEdit}
            />
            <PrivateRoute
              path="/settings"
              exact
              component={Settings}
            />

            
          </Switch>
        </Router>
      </PersistGate>
    </Provider>

  );
}
