import React, { Component, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import Backbutton from "../../Backbutton"

//Table
import MUIDataTable from "mui-datatables";

import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LockIcon from "@material-ui/icons/Lock";
import { Tooltip } from "@material-ui/core";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import SweetAlert from "react-bootstrap-sweetalert";

import axios from "axios";

import { connect } from "react-redux";

import { setFitmentFor } from "../../../actions/FitmentForAction";
import FitmentForAdd from "./FitmentForAdd";

const FitmentFor = ({ history, server, access_token }) => {

  const [fitmentFor, setFitmentFor] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllFitmentFor();
  }, []);

  const getAllFitmentFor = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    setLoading(true);

    axios
      .get('https://backend.arihantauto.in/' + "api/fitment-for", config)
      .then((response) => {
        setFitmentFor(response.data);
        setLoading(false)
      })
      .catch((error) => { });
  };

  const deleteCall = (ids) => {

    setAlert(
      <SweetAlert
        warning
        title="Are you Sure you want to delete this fitment?"
        onConfirm={() => deleteRecord(ids)}
        onCancel={hideAlert}
        confirmBtnBsStyle="danger"
        cancelBtnStyle={{ color: "red" }}
        confirmBtnText="Delete!"
        cancelBtnText="Cancel"
        showCancel
      >
        This action cannot be reverted
      </SweetAlert>
    );
  };

  const deleteRecord = (ids) => {

    const parameter = {
      _method: "delete",
    };
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://backend.arihantauto.in/' + `api/fitment-for/${ids}`, parameter, config)
      .then((response) => {
        hideAlert();
        getAllFitmentFor();
        deleted();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleted = () => {

    setAlert(
      <SweetAlert success title="Deleted" onConfirm={hideAlert}>
        Fitment deleted
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
        filter: true,
        sort: false,
      },
    },
    {
      name: "fitment_name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (ids, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    history.push(
                      "/FitmentFor/Edit",
                      fitmentFor.filter((item) => {
                        return item.id === ids;
                      })
                    )
                  }
                  aria-label="Edit"
                  style={{
                    backgroundColor: "#00ACC1",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Edit"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                  onClick={() => deleteCall(ids)}
                  aria-label="delete"
                  style={{
                    backgroundColor: "#FF0A37",
                    padding: 5,
                    marginRight: 5,
                    color: "#ffffff",
                  }}
                  className="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
        download: false,
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    selectableRows: false,
    print: false,
    download: true,
  };

  return (
    <>
      <div className="dashboardMainDivContainer">
        {alert}
        <div className="card m-3 mt-5">

          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>
            <div className="titles" style={{ alignSelf: 'flex-start' }}>Fitment For</div>

            <div style={{ position: "absolute", right: 30 }}>
              <Link className="BtnLinkDist" to="/FitmentFor/Add">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: '#F05225' }}
                  startIcon={<AddCircleIcon />}
                >
                  Add New Fitment For
                </Button>
              </Link>
            </div>

          </div>


          <div style={{ marginTop: "2%" }}>
            {loading == true ?

              <div style={{ marginTop: "2%" }}>
                <Skeleton height={70} />
                <Skeleton count={15} style={{ marginTop: 15 }} />
              </div>
              :


              <MUIDataTable
                title={"List of all fitment for"}
                data={fitmentFor}
                columns={columns}
                options={options}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  fitment_for: state.fitment_for,
});

export default connect(mapStateToProps, { setFitmentFor })(FitmentFor);
