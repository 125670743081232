import React, { useEffect, Component, useState } from "react";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Brand = (
  server
) => {

  const [dashboardStats, setDashboardStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {

    getDashboardStats();

  }, []);

  const getDashboardStats = () => {

    setLoading(true);

    axios
      .get('https://backend.arihantauto.in/' + "api/get-dashboard-stats")
      .then((response) => {
        setDashboardStats(response.data.dashboardStats);
        setLoading(false);
      })
      .catch((error) => { });
  };


  return (
    <>
      {dashboardStats &&

        <div className="dashboardMainDivContainer">
          <div className="card m-3 mt-5">
            <div className="titles">Dashboard</div>


            {loading == true ?

              <div style={{ marginTop: "2%", display: 'flex', flexDirection: 'row' }}>
                <Skeleton height={70} width={150} />
                <Skeleton height={70} width={150} style={{ marginLeft: 20 }} />
                <Skeleton height={70} width={150} style={{ marginLeft: 20 }} />
                <Skeleton height={70} width={150} style={{ marginLeft: 20 }} />
                <Skeleton height={70} width={150} style={{ marginLeft: 20 }} />
              </div>
              :

              <div class="container pt-5" style={{ marginTop: 30 }}>

                <div class="row align-items-stretch" style={{ display: 'flex', flexWrap: 'wrap' }}>

                  <div class="c-dashboardInfo col-lg-3 col-md-6">
                    <div class="wrap">
                      <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title" style={{textAlign:'left'}}>
                        Categories<svg
                          class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24"
                          aria-hidden="true" role="presentation">
                          <path fill="none" d="M0 0h24v24H0z"></path>

                        </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count" style={{textAlign:'left'}}>{dashboardStats.category_count}</span>
                    </div>
                  </div>

                  <div class="c-dashboardInfo col-lg-3 col-md-6">
                    <div class="wrap">
                      <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title" style={{textAlign:'left'}}>
                        Brands<svg
                          class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path fill="none" d="M0 0h24v24H0z"></path>

                        </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count" style={{textAlign:'left'}}>{dashboardStats.brand_count}</span>
                    </div>
                  </div>

                  <div class="c-dashboardInfo col-lg-3 col-md-6">
                    <div class="wrap">
                      <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title" style={{textAlign:'left'}}>
                        Users<svg
                          class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path fill="none" d="M0 0h24v24H0z"></path>

                        </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count" style={{textAlign:'left'}}>{dashboardStats.user_count}</span>
                    </div>
                  </div>

                  <div class="c-dashboardInfo col-lg-3 col-md-6">
                    <div class="wrap">
                      <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title" style={{textAlign:'left'}}>
                        Products<svg
                          class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path fill="none" d="M0 0h24v24H0z"></path>

                        </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count" style={{textAlign:'left'}}>{dashboardStats.product_count}</span>
                    </div>
                  </div>


                  <div class="c-dashboardInfo col-lg-3 col-md-6">
                    <div class="wrap">
                      <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title" style={{textAlign:'left'}}>
                        Orders<svg
                          class="MuiSvgIcon-root-19" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                          <path fill="none" d="M0 0h24v24H0z"></path>

                        </svg></h4><span class="hind-font caption-12 c-dashboardInfo__count" style={{textAlign:'left'}}>{dashboardStats.order_count}</span>
                    </div>
                  </div>

                </div>
              </div>
            }

          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
});

export default connect(mapStateToProps, {})(Brand);


