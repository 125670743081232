import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Grid, Checkbox, TextareaAutosize } from "@material-ui/core";
import Backbutton from "../../Backbutton";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { connect } from "react-redux";
import { CircularProgress } from '@material-ui/core';

import SweetAlert from "react-bootstrap-sweetalert";

let schema = yup.object({
  name: yup.string().required("Name is Required"),
  username: yup.string().required("username is Required").typeError(""),
  email: yup.string().email().typeError(""),
  whatsapp_no: yup.string().min(10, 'Please enter 10 digits WhatsApp number').max(10, 'Please enter 10 digits WhatsApp number').required("WhatsApp number is required"),
  phone_no: yup.string().min(10, 'Please enter 10 digits mobile number').max(10, 'Please enter 10 digits mobile number').required("Phone number is required"),
});

function EditGeneric({ server, history, access_token, role,location }) {

  const [UsernameError, setUsernameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [PhoneError, setPhoneError] = useState("");
  const [AddressError, setAddressError] = useState("");
  const [StateError, setStateError] = useState("");
  const [DistrictError, setDistrictError] = useState("");
  const [WhatsappError, setWhatsappError] = useState("");
  const [hidePrintOrderError, setHidePrintOrderError] = useState("");
  const [alert, setAlert] = useState(null);
  const [onLoading, setOnLoading] = React.useState(false);

  
  const hideAlert = () => {
    setAlert(null);
  };
  const added = () => {
    setAlert(
      <SweetAlert
        success
        title="User Updated Successfully"
        onConfirm={hideAlert}
      >
        Generic user has been updated
      </SweetAlert>
    );

    history.push('/Users');

  };

  const editUser = (values, resetForm) => {

    setOnLoading(true);

    const data = {
      name: values.name,
      owner_name: values.owner_name,
      username: values.username,
      email: values.email,
      whatsapp_no: values.whatsapp_no,
      phone_no: values.phone_no,
      hide_print_order:values.hide_print_order,
      address:values.address,
      state:values.state,
      district:values.district,
      _method:"put"
    };    

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    axios
      .post('https://backend.arihantauto.in/' + "api/user/generic/"+location.state[0]?.id, data, config)
      .then((response) => {
        
        added();
        resetForm();
        setUsernameError("");
        setEmailError("");
        setPhoneError("");
        setWhatsappError("");
        setOnLoading(true);
      })
      .catch((error) => {
        console.log(error.response.data.errors);

        if (error.response.data.errors.hasOwnProperty("username")) {
          setUsernameError(error.response.data.errors.username[0]);
        }

        if (error.response.data.errors.hasOwnProperty("email")) {
          setEmailError(error.response.data.errors.email[0]);
        }

        if (error.response.data.errors.hasOwnProperty("phone_no")) {
          setPhoneError(error.response.data.errors.phone_no[0]);
        }

        if (error.response.data.errors.hasOwnProperty("whatsapp_no")) {
          setWhatsappError(error.response.data.errors.whatsapp_no[0]);
        }

        if (error.response.data.errors.hasOwnProperty("hidePrintOrder")) {
          hidePrintOrderError(error.response.data.errors.hide_print_order[0]);
        }

      });
  };



  return (
    <div className="grayBackgroundBox">
      {alert}
      <div className="card container">
        <Formik
          initialValues={{
            name: location?.state[0]?.name,
            owner_name: location?.state[0]?.owner_name,
            username: location?.state[0]?.username,
            email: location?.state[0]?.email,
            whatsapp_no: location?.state[0]?.whatsapp_no,
            phone_no: location?.state[0]?.phone_no,
            address:location?.state[0]?.address,
            state:location?.state[0]?.state,
            district:location?.state[0]?.district,
            gst_number:location?.state[0]?.gst_number,            
            hide_print_order: location?.state[0]?.show_print_order == 0?true:false,
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            editUser(values, resetForm);
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => (
            
            <form className="container" onSubmit={handleSubmit}>
              <div>
                <Backbutton />{" "}
              </div>
              <div className="title">Edit Generic User</div>
              
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Billing Name*"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && (
                      <p style={{ color: "red" }}>{errors.name}</p>
                    )}
                  </div>

                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Owner Name"
                      name="owner_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.owner_name}
                    />
                    {errors.owner_name && (
                      <p style={{ color: "red" }}>{errors.owner_name}</p>
                    )}
                  </div>


                  <div className="col-12 col-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Username*"
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    {errors.username && touched.username && (
                      <p style={{ color: "red" }}>{errors.username}</p>
                    )}
                    {UsernameError && (
                      <p style={{ color: "red" }}>{UsernameError}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Email"
                      name="email"                     
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    )}
                    {EmailError && <p style={{ color: "red" }}>{EmailError}</p>}
                  </div>
                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Whatsapp"
                      name="whatsapp_no"                     
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.whatsapp_no}
                    />
                    {errors.whatsapp_no && touched.whatsapp_no && (
                      <p style={{ color: "red" }}>{errors.whatsapp_no}</p>
                    )}
                    {WhatsappError && (
                      <p style={{ color: "red" }}>{WhatsappError}</p>
                    )}
                  </div>
                  <div className="col-12 43poicol-md-6 inputFieldContainer">
                    <TextField
                      className="inputField"
                      id="standard-basic"
                      label="Phone"
                      name="phone_no"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone_no}
                    />
                    {errors.phone_no && touched.phone_no && (
                      <p style={{ color: "red" }}>{errors.phone_no}</p>
                    )}
                    {PhoneError && <p style={{ color: "red" }}>{PhoneError}</p>}
                  </div>


                  <div className="col-12 43poicol-md-6 inputFieldContainer" style={{marginTop:20}}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="Address"
                      multiline={true}
                      name="address"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {errors.address && touched.address && (
                      <p style={{ color: "red" }}>{errors.address}</p>
                    )}
                    {AddressError && <p style={{ color: "red" }}>{AddressError}</p>}
                  </div>

                  <div className="col-12 43poicol-md-6 inputFieldContainer" style={{marginTop:20}}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="State"
                      multiline={true}
                      name="state"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                    />
                    {errors.state && touched.state && (
                      <p style={{ color: "red" }}>{errors.state}</p>
                    )}
                    {StateError && <p style={{ color: "red" }}>{StateError}</p>}
                  </div>

                  <div className="col-12 43poicol-md-6 inputFieldContainer" style={{marginTop:20}}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      label="District"
                      multiline={true}
                      name="district"                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.district}
                    />
                    {errors.district && touched.district && (
                      <p style={{ color: "red" }}>{errors.district}</p>
                    )}
                    {DistrictError && <p style={{ color: "red" }}>{DistrictError}</p>}
                  </div>

                {values.gst_number &&

                  <div className="col-12 43poicol-md-6 inputFieldContainer" style={{marginTop:20}}>
                       <strong>GST Number:</strong> {values.gst_number}

                  </div>
                }





                  <div className="col-6 col-md-3" style={{marginTop:20}}>
                    <Checkbox
                      className=""
                      id="hide-print-order-button"
                      name="hide_print_order"
                      label="Hide Print Order Button"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.hide_print_order}
                      value={values.hide_print_order}
                    />
                    <label for="hide-print-order-button">Hide Print Order Button</label>
                    {errors.hide_print_order && touched.hide_print_order && (
                      <p style={{ color: "red" }}>{errors.hide_print_order}</p>
                    )}
                  </div>

                </div>
              </div>
              
              <div style={{ textAlign: "center" }} className="text-center mt-5">
                <Button
                  className="formSubmit"
                  variant="contained"
                  type="submit"
                  color="primary"
                  style={{backgroundColor:'#F05225'}}
                  disabled={!isValid || onLoading}
                  disableElevation
                >
                 {onLoading ? <CircularProgress style={{color:'white'}} /> : "Save User"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
});

export default connect(mapStateToProps, {})(EditGeneric);
